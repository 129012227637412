
import React from "react";
import PropTypes from "prop-types";

// other components
import Dropzone from 'react-dropzone';

// images 
import cloudUpload from "../../../images/cloudUpload.svg"

class AddTableUpload extends React.Component {

    render() {

        return (
            <div>
                <div className="p-4 m-4 border-dashed rounded-lg text-center border-2" data-testid="addTableUpload">
                {
                    this.props.files.length > 0
                        ? <div style={{ padding: "0px" }}>
                                Uploading File
                            </div>
                        : <Dropzone maxSize={20000000}
                                onDrop={this.props.onDrop}
                                accept={[".csv", ".tsv", ".txt", "text/csv", "application/vnd.ms-excel", "application/csv", "text/x-csv", "application/x-csv", "text/comma-separated-values", "text/x-comma-separated-values"]}
                                multiple={false}
                            >
                                {
                                    ({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} >
                                            <img alt="Upload" src={cloudUpload} className="mx-auto h-12" />
                                            <input {...getInputProps()} data-testid="Dropzone" />
                                            <button>Drop file here or click to browse</button>
                                        </div>
                                    )
                                }
                            </Dropzone>

                }
                </div>
                <div className="text-red-500 pl-4">
                    {this.props.error.split('\n').map((s,i) => <p key={i}>{s}</p>)}
                </div>
            </div>
        )
    }
}

export default AddTableUpload;

AddTableUpload.propTypes = {
    files: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
};
