import React from "react";

// custom components
import Input from "../common/form/Input.jsx"

const OnboardingOption = (props) => {

    return (
        <div className="flex flex-col">
            <div className="font-bold px-2 pt-2">
                {props.title}
            </div>
            <Input placeHolder={props.placeholder}
                    outerClass={props.outerClass || "sm:w-1/2"}
                    className=""
                    error={props.error}
                    inputValue={props.inputValue}
                    onChange={props.onChange}
                    onBlur={props.onBlur || undefined}
                    type={props.type || undefined}
                    disabled={props.disabled || undefined}
            />
        </div>
    )

};

export default OnboardingOption;