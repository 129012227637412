import React, { createContext } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

export const AuthContext = createContext(null);


/////////////////////

export const AuthProvider = props => {
    
    const domain = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AUTH0_DOMAIN : window.AUTH0_DOMAIN;
    const clientId = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AUTH0_CLIENT_ID : window.AUTH0_CLIENT_ID; 
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };
              
    return (
        
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
            // scope="read:current_user update:current_user_metadata"
        >
                {props.children}
        </Auth0Provider>
    );
};