import React from "react";
import PropTypes from "prop-types";

// Custom Components
import DatabaseList from "./dblist/DatabaseList"
import Container from "../common/Container"
import Card from "../common/Card"
import AddTable from "./addTable/AddTable"

class NoSelection extends React.Component {
    // constructor(props) {
    //     super(props);
    // }


    render() {
        return (
            <Container leader="Databases">
                <Card title="Edit a table">
                    <DatabaseList
                        selectedTable={{}}
                        inNavBar={false}
                        dbData={this.props.dbData}
                        setTable={this.props.setTable}
                        addTable={this.props.addTable}
                        addSchema={this.props.addSchema}
                        tenant={this.props.tenant}
                    />
                </Card>
                
                <Card title="Add a new table">
                    <AddTable 
                        schemas={this.props.dbData} 
                        addTable={this.props.addTable} 
                        inModal={false}
                    />
                </Card>
            </Container>
        );
    }
}

export default NoSelection;

NoSelection.propTypes = {
    tenant: PropTypes.string.isRequired,
    dbData: PropTypes.array.isRequired,
    setTable: PropTypes.func.isRequired,
    addTable: PropTypes.func.isRequired,
    addSchema: PropTypes.func.isRequired,
};
