import React, {useContext, useState} from "react";
// import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

// contexts
import { SocketContext } from "../contexts/socketContext";

// Custom Components
import Container from "../components/common/Container"
import Card from "../components/common/Card"
import ChangeNameModal from "../components/account/ChangeNameModal"
import SettingsContainer from "../components/common/settings/SettingsContainer"
import SettingsListContainer from "../components/common/settingsList/SettingsListContainer"
import Button from "../components/common/button/Button"
import ButtonDanger from "../components/common/button/ButtonDanger";
import TenantItemManage from "../components/tenant/TenantItemManage";

const AccountPage = () => {
    
    // constructor(props) {
    //     super(props);      
    // }
    
    const { socket, userProfile, updPrimaryTenant, leaveTenant } = useContext(SocketContext);
    const { logout } = useAuth0();


    let tenants = userProfile.tenants || {}
    let primaryTenant = userProfile.primary_tenant || ""

    const [nameModalOpen, setNameModalOpen] = useState(false)

    
    const submitPWReset = () => {
        socket.emit("reqPasswordReset", {})
    }

    const changeName = (firstname, lastname) => {
        socket.emit("updName", {firstname, lastname})
        setNameModalOpen(false)
    }

    return (
        <div className="pl-20 h-screen w-full">
            <Container leader="Account Settings">
                <div className="mt-4 w-32">
                    <ButtonDanger title="Log out" onClick={() => logout({ returnTo: window.location.origin })} />
                </div>
                <Card title="Profile Settings">
                    <SettingsContainer
                        setting="Reset Password"
                        settingDesc="Trigger reset password email to be sent"
                    >
                        <Button title="Send Password Reset" onClick={()=>{submitPWReset()}} />
                    </SettingsContainer>
                    <SettingsContainer
                        setting="Display Name"
                        settingDesc={`Change the way your name is displayed in the application`}
                    >
                        <Button title="Change Name" onClick={()=>{setNameModalOpen(true)}} />
                        {
                            nameModalOpen
                            ? <ChangeNameModal onCancel={()=>{setNameModalOpen(false)}} 
                                changeName={changeName}
                                firstname={userProfile.firstname || ""}
                                lastname={userProfile.lastname || ""}
                              />
                            : null
                        }
                    </SettingsContainer>
                    {/* <SettingsContainer
                        setting="Avatar"
                        settingDesc="Change the avatar associated with your profile"
                    >
                        <Button title="Change Avatar" />
                    </SettingsContainer> */}
                </Card>
                
                <Card title="Instances">
                    <SettingsListContainer >
                        {
                            Object.keys(tenants).map(function(k, i) {
                                return <TenantItemManage 
                                            key={k} 
                                            tenantName={k}
                                            primaryTenantFlag={primaryTenant === k}
                                            setPrimaryTenant={updPrimaryTenant}
                                            leaveTenant={leaveTenant}
                                        />
                            })
                        }
                    </SettingsListContainer>
                </Card>

            </Container>
        </div>
    );
}

export default AccountPage;