import React from "react";
// import PropTypes from "prop-types";


class GridEditor extends React.Component {
    
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //     }
    // }

    autoFocusAndSelect = (input) => {
        input?.focus();
        input?.select();
    }

    render() {

        let {fullRow, column} = this.props

        return (
            <input 
                ref={this.autoFocusAndSelect}
                className="rdg-text-editor
                            appearance-none
                            box-border
                            w-full
                            h-full
                            px-4
                            text-gray-900
                            dark:text-gray-100
                            bg-gray-100
                            dark:bg-gray-900
                            border-gray-600
                            dark:border-gray-800
                            border-2
                            border-solid
                            align-top
                            "
                value={fullRow[column.key] || ''}
                onChange={e => this.props.onRowChange({ ...this.props.row, __WIO_CHANGED:true, [column.key]: e.target.value })}
                onBlur={() => this.props.onClose(true)}
            />
        );
    }
}

export default GridEditor;

GridEditor.propTypes = {
    //    title: PropTypes.string.isRequired,
    //    content: PropTypes.arrayOf(PropTypes.string).isRequired,
};