import React from "react";
import PropTypes from "prop-types";

class Container extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className="w-full sm:pl-20">
                <div className="p-4 mx-auto text-left md:max-w-7xl xl:max-w-5xl">
                    <div className="">
                        <span className="h-16 w-full font-bold sm:text-xl xl:text-2xl xl:w-5/12">{this.props.leader}</span>
                    </div>
                    <div className="h-screen-32 overflow-y-auto">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Container;

Container.propTypes = {
    leader: PropTypes.string,
};
