const processRow = (row, cols) => {

    // if only has __WIO_ID and __WIO_NEW
    if (Object.keys(row).length === 2 && Object.keys(row).some((k) => {return k === "__WIO_NEW"})) {
        return ""
    }

    let finalVal = "";
    cols.forEach( (c, j) => {
        if (row.hasOwnProperty(c.key)) {
            let innerValue = row[c.key] === null ? "" : row[c.key].toString();
            if (row[c.key] instanceof Date) {
                innerValue = row[c.key].toLocaleString();
            };
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
    });
    return finalVal;
};

const exportToCsv = (filename, rows, cols) => {

    let csvFile = '';

    // process columns
    for (let i = 0; i < cols.length; i++) {
        if (i > 0) {
            csvFile += ',';
        }
        csvFile += cols[i].name;        
    }

    // process rows
    for (let j = 0; j < rows.length; j++) {
        if (processRow(rows[j], cols) !== "") {
            csvFile += '\n';
        }
        csvFile += processRow(rows[j], cols);
    }

    let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, `${filename}.csv`);
    } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${filename}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export {exportToCsv}