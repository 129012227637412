
import React from "react";
import PropTypes from "prop-types";
import ButtonDanger from "../button/ButtonDanger";

class Modal extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className="fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center"
                style={{ background: "rgba(0,0,0,.7)" }}>
                <div className="shadow-lg modal-container bg-white dark:bg-gray-700 w-11/12 mx-auto rounded shadow-lg z-50 overflow-y-auto md:max-w-2xl lg:max-w-4xl">
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-2xl font-bold">{this.props.title}</p>
                            <div onClick={this.props.cancel} className="modal-close cursor-pointer z-50" title="close">
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                    viewBox="0 0 18 18">
                                    <path
                                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div className="my-5">
                            {this.props.children}
                        </div>
                        {
                            this.props.enableDanger
                            ?   <div className="flex justify-start pt-14 -my-12 pl-2 w-36">
                                    <ButtonDanger title={this.props.dangerTitle} onClick={this.props.dangerOnClick} />
                                </div>
                            : null
                        }
                        <div className="flex justify-end pt-2">
                            {
                                this.props.enablePrimary
                                ?   <div>
                                        <button
                                            onClick={this.props.primaryOnClick}
                                            className="focus:outline-none modal-close px-4 mx-2 bg-wblue p-2 rounded text-white hover:bg-wblue-light hover:text-black"
                                        >
                                            {this.props.primaryTitle}
                                        </button>
                                    </div>
                                : null
                            }
                            <button
                                onClick={this.props.cancel}
                                className="focus:outline-none modal-close px-4 bg-gray-400 p-2 rounded text-white hover:text-black hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    cancel: PropTypes.func.isRequired,

    // Primary Button
    enablePrimary: PropTypes.bool,
    primaryTitle: PropTypes.string,
    primaryOnClick: PropTypes.func,
    
    // Danger Button
    enableDanger: PropTypes.bool,
    dangerTitle: PropTypes.string,
    dangerOnClick: PropTypes.func,
};