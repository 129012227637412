
import React from "react";
import PropTypes from "prop-types";

// libraries
import DataGrid from 'react-data-grid';
import Papa from "papaparse"

// helpers
import { validateColumn, csvComplete, backfillRows } from "../../../helpers/fileValidation"

// custom components
import AddTableUpload from "./AddTableUpload"
import AddTableColumnDefs from "./AddTableColumnDefs"
import ButtonPrimary from "../../common/button/ButtonPrimary";
import Button from "../../common/button/Button";
import Dropdown from '../../common/dropdown/Dropdown'
import Modal from "../../common/modals/Modal"
import Input from "../../common/form/Input"

class AddTable extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            activeColumn: -1,
            files: [],
            columns: [],
            rows: [],
            columnName: "",
            dataType: "Text",
            schema: this.props.sourceSchema || "-none-",
            tableName: "",
            validationErrors: {},
        }
    }

    onDrop = (files, rejectedfiles, event) => {
        let {validationErrors} = this.state
        validationErrors = {}

        // handle rejections
        if (rejectedfiles.length > 0) {
            if (rejectedfiles[0].errors.length > 0) {
                validationErrors["file"] = rejectedfiles[0].errors[0].code === "file-invalid-type"
                    ? "File must be a delimited text file (csv, tsv, etc)"
                    : rejectedfiles[0].errors[0].message

                this.setState({
                    validationErrors
                });
            }
        } else {
            // handle accepts
            
            files.forEach((f) => {
                Papa.parse(f, {
                    header: true,
                    complete: this.finishDrop,
                    error: this.errorDrop,
                    dynamicTyping: true,
                    skipEmptyLines: true
                });                           
            });

        }
        
    }

    errorDrop = () => {
        let validationErrors = {
            file: "Bad file, please try a different one"
        }

        this.setState({validationErrors})
    }

    finishDrop = async (r, f) => {
        
        let res = await csvComplete(r, f)

        // this fxn will be called by a second parse w/o dynamic typing
        // so that any detected text columns can retain preceding 0s
        const finalizeRows = async (r2, f2) => {
        
            let res2 = await backfillRows(r2, f2, res.columns, res.rows)
    
            if (res2.success) {
                let newFiles = []
                newFiles.push(res.file)
                this.setState({
                    files: newFiles,
                    validationErrors: res.validationErrors || {},
                    columns: res.columns || [],
                    rows: res2.rows || [],
                })
            } else {
                this.setState({
                    validationErrors: res.validationErrors
                })
            }
        }        

        if (res.success) {
            // parse again, this time without dynamic types
            Papa.parse(f, {
                header: true,
                complete: finalizeRows,
                dynamicTyping: false,
                skipEmptyLines: true
            });
        } else {
            this.setState({
                validationErrors: res.validationErrors
            })
        }
    }

    addTable = () => {
        let { schema, validationErrors } = this.state

        validationErrors = this.validateTable(this.state.tableName)

        if (validationErrors.hasOwnProperty("table") || validationErrors.hasOwnProperty("schema")) {
            this.setState({
                validationErrors
            })
            return
        }

        if (schema === "-none-") {
            validationErrors["schema"] = "Select a schema"
            this.setState({
                validationErrors
            })
            return
        }


        let def = {};

        def.columns = this.state.columns;
        def.schema = this.state.schema;
        def.tableName = this.state.tableName.toUpperCase();
        def.rows = this.state.rows;
        this.props.addTable(def);

        this.resetState()

        if (this.props.inModal) {
            this.props.toggleModal()
        }
    }

    resetState = () => {
        this.setState({
            files: [],
            activeColumn: -1,
            columns: [],
            rows: [],
            columnName: "",
            dataType: "Text",
            schema: this.props.sourceSchema || "-none-",
            tableName: "",
            validationErrors: {},
        });
    }

    addColumn = () => {
        let { activeColumn, columns, columnName, dataType, validationErrors } = this.state;

        let errors = validateColumn(columns, columnName, dataType, validationErrors, activeColumn)

        if (errors.hasOwnProperty("name") || errors.hasOwnProperty("type")) {
            this.setState({
                validationErrors: errors,
            });
            return;
        }

        let newCol = {
            name: columnName,
            type: dataType,
        }

        // if editing
        if (activeColumn > -1) {
            columns.splice(activeColumn, 1, newCol)
        } else {
            columns.push(newCol);
        }

        this.setState({
            activeColumn: -1,
            columns,
            columnName: "",
            dataType: "Text",
            validationErrors: errors,
        });
    }

    editColumn = (i) => {
        let { activeColumn, columns, columnName, dataType, validationErrors } = this.state;

        if (activeColumn === i) {
            return
        }

        delete validationErrors.name;
        delete validationErrors.type;

        if (i === -1) {
            this.setState({
                activeColumn: -1,
                columnName: "",
                dataType: "Text",
                validationErrors,
            });
        } else {
            let columnToEdit = columns[i];

            columnName = columnToEdit.name;
            dataType = columnToEdit.type;
            activeColumn = i;

            this.setState({
                activeColumn,
                columnName,
                dataType,
                validationErrors,
            });
        }
    }

    deleteColumn = (i) => {
        let { columns } = this.state;

        columns.splice(i, 1);

        this.setState({
            columns
        });
    }

    onChangeColumnName = (e) => {
        this.setState({
            columnName: e.target.value
        });
    }

    onChangeDataType = (e) => {
        this.setState({
            dataType: e.target.value
        });
    }

    validateTable = (tableName) => {
        let { validationErrors } = this.state
        let hasError = false;

        const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
        if (!regex.test(tableName)) {
            validationErrors["table"] = "Name can only contain letters, numbers, and underscores"
            hasError = true
        }

        if (tableName.length < 1) {
            validationErrors["table"] = "Table name must be filled in"
            hasError = true
        }

        if (!hasError && validationErrors.hasOwnProperty("table")) {
            delete validationErrors.table
        }

        return validationErrors
    }

    onChangeTableName = (e) => {
        let errors = this.validateTable(e.target.value)

        this.setState({
            tableName: e.target.value,
            validationErrors: errors

        });
    }

    onChangeSchema = (e) => {
        let { validationErrors } = this.state;

        if (validationErrors.hasOwnProperty("schema")) {
            delete validationErrors.schema
        }

        this.setState({
            schema: e.target.value,
            validationErrors
        });
    }

    getColumns = () => {
        let newColumns = [];

        this.state.columns.forEach(c => {
            
            c.headerRenderer = p => (
                
                <div className="leading-7">
                    <div className="text-left">{c.name}</div>
                    <div className="flex-auto py-1.5 italic font-normal text-xs text-left">({c.type})</div>
                </div>
                
            );
            
            newColumns.push(c)
        })
        return newColumns
    }

    render() {

        let form = <div data-testid="addTableForm">
                        {
                            this.state.columns.length > 0
                                ? null
                                : <AddTableUpload
                                    files={this.state.files}
                                    onDrop={this.onDrop}
                                    error={this.state.validationErrors.file || ""}
                                />
                        }
                        {
                            this.state.columns.length < 1 && this.state.files.length < 1
                                ? <div className="mt-10 font-bold text-lg">Or define a table from scratch below</div>
                                : null
                        }
                        {
                            this.state.files.length > 0 || this.state.rows.length > 0
                                ? null
                                : <AddTableColumnDefs
                                    activeColumn={this.state.activeColumn}
                                    columns={this.state.columns}
                                    errors={this.state.validationErrors}
                                    schema={this.state.schema}
                                    schemaDisabled={this.props.sourceSchema ? true : false}
                                    schemas={this.props.schemas}
                                    tableName={this.state.tableName}
                                    dataType={this.state.dataType}
                                    columnName={this.state.columnName}
                                    defType={"add"}
                                    addTable={this.addTable}
                                    addColumn={this.addColumn}
                                    editColumn={this.editColumn}
                                    deleteColumn={this.deleteColumn}
                                    onChangeColumnName={this.onChangeColumnName}
                                    onChangeDataType={this.onChangeDataType}
                                    onChangeSchema={this.onChangeSchema}
                                    onChangeTableName={this.onChangeTableName}
                                    inModal={this.props.inModal}
                                    resetState={this.resetState}
                                />
                        }
                        {
                            this.state.rows.length > 0
                                ?   <div data-testid="uploadFileGrid">
                                        <div className="flex flex-row dark:text-black">
                                            <Dropdown name="schemaSelect"
                                                outerDivClass="flex flex-col w-1/2 mr-2"
                                                value={this.state.schema}
                                                onChange={this.onChangeSchema} 
                                                error={this.state.validationErrors.hasOwnProperty("schema") ? this.state.validationErrors.schema : undefined }
                                            >
                                                <option value={"-none-"}>Choose Schema</option>
                                                {
                                                    this.props.schemas.map((s, i) => {
                                                        return <option key={i} value={s.schema}>{s.schema}</option>
                                                    })
                                                } 
                                            </Dropdown>
                                            <Input placeHolder="Table Name"
                                                    outerClass="w-1/2 mr-1"
                                                    className=""
                                                    error={this.state.validationErrors.hasOwnProperty("table") ? this.state.validationErrors.table : undefined }
                                                    inputValue={this.state.tableName}
                                                    onChange={this.onChangeTableName}
                                            />
                                        </div>
                                        <DataGrid columns={this.getColumns()}
                                                defaultColumnOptions={{
                                                    sortable: true,
                                                    resizable: true,
                                                    minWidth: 120
                                                }}
                                                enableFilterRow={true}
                                                headerRowHeight={60}
                                                rows={this.state.rows}
                                                rowKeyGetter={(row) => { 
                                                    return row.__WIO_ID 
                                                }}
                                        />
                                        {
                                            this.props.inModal
                                            ?   null
                                            :   <div className="flex justify-end py-2">
                                                    <div className="mx-1 w-48">
                                                        <ButtonPrimary title="Add" onClick={this.addTable} />
                                                    </div>
                                                    <div className="mx-1 w-48">
                                                        <Button title="Cancel" onClick={this.resetState} />
                                                    </div>
                                                </div>
                                        }
                                        
                                    </div>
                                : null
                        }

                    </div>

        return (
            this.props.inModal
                ?   <Modal cancel={this.props.toggleModal} 
                        title={"Add table"}
                        enablePrimary={this.state.columns.length > 0 || this.state.files.length > 0}
                        primaryTitle="Add" 
                        primaryOnClick={this.addTable}
                    >
                        {form}
                    </Modal>
                
                :   form
        );
    }
}

export default AddTable;

AddTable.propTypes = {
    schemas: PropTypes.array.isRequired,
    addTable: PropTypes.func.isRequired,
    inModal: PropTypes.bool.isRequired,
    sourceSchema: PropTypes.string,
    toggleModal: PropTypes.func,
    
};
