import {
    Route,
    Redirect,
} from "react-router-dom";
import { useContext } from "react";

import { SocketContext } from "../../contexts/socketContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const PrivateRoute = ({ children, ...rest }) => {
    const { userProfile } = useContext(SocketContext)

    let onboarded = userProfile.hasOwnProperty("onboarding_status") 
                    && userProfile.onboarding_status === "complete";

    return (
        <Route
            {...rest}
            render={ ({ location }) => 
                onboarded 
                    ?   (children) 
                    :   (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location }
                                }}
                            />
                        )
            }
        />
    );
}


export default withAuthenticationRequired(PrivateRoute);