import React from 'react';

const TableIcon = ({className}) => {
    return (
    <svg fill="none" stroke="currentColor" className={className || ""} viewBox="0 0 16 16">
        <path d="M0 3v11h16v-11h-16zM6 10v-2h4v2h-4zM10 11v2h-4v-2h4zM10 5v2h-4v-2h4zM5 5v2h-4v-2h4zM1 8h4v2h-4v-2zM11 8h4v2h-4v-2zM11 7v-2h4v2h-4zM1 11h4v2h-4v-2zM11 13v-2h4v2h-4z"></path>
    </svg>
    )
}

export default TableIcon;




