import React, { useContext, useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, withRouter  } from "react-router-dom";

// contexts
import { SocketContext } from "../contexts/socketContext"; 

// Images
import WinterfaceFull from "../images/WinterfaceFull"

// Custom Components
import Button from "../components/common/button/Button"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const emptyPlan = {
    tier: "",
    monthly: false,
    seats: 1
};

const Payment = (props) => {

    // context
    const { socket, userProfile } = useContext(SocketContext)

    // state
    const [planData, setPlanData] = useState(emptyPlan);
    const [gotInitial, setInitial] = useState(false);

    // query params
    const session_id = useQuery().get("session_id");

    // fulfillPlan is a callback tied to "updSubscription" to process response
    const fulfillPlan = useCallback((d) => {
        setPlanData({
            ...planData,
            ...d
        })
        
    }, [planData]);

    const confirmSubscription = useCallback((session_id, cb) => {
        socket.emit("updSubscription",
            { session_id },
            (r) => { cb(r) }
        )
    }, [socket]);

    // initial request for confirmation
    useEffect(() => {

        // only get data if plan tier is not set yet
        if (!gotInitial) {            
            
            confirmSubscription(session_id, fulfillPlan)

            // mark that this has been run
            setInitial(true)
        }

    }, [confirmSubscription, fulfillPlan, setInitial, gotInitial, session_id]);
    
    ///////////////
    // component //
    ///////////////



        
    ////////////
    // return //
    ////////////
    return (
        <div className="pl-20 py-20 h-screen w-full">
                <div className="mx-auto max-w-md">
                    <WinterfaceFull className="fill-current dark:text-white" />
                </div>
                <div className="pt-4">
                    {`Successfully updated to the ${planData.tier} tier with ${planData.seats} seat${planData.seats>1?"s":""}`}
                </div>

                <div className="grid place-content-center">
                    <div className="pt-4 w-40">
                        <NavLink exact to={`/${userProfile.currentTenant}/settings`}
                            title="Back to Settings"
                            onClick={() => {}}
                        >
                            <Button title="Back to Settings" />
                        </NavLink>
                    </div>
                </div>
    </div>
    );
}


export default withRouter(Payment);