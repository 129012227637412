import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation, withRouter  } from "react-router-dom";

// contexts
import { PageContext } from "../contexts/pageContext";
import { DataContext } from "../contexts/dataContext";
import { SocketContext } from "../contexts/socketContext"; 

// custom components
import Page404 from "./Page404"
import PageLoading from "./PageLoading"
import InnerNavBar from "../components/InnerNavBar";
import Grid from "../components/database/Grid";
import GridFilterModal from "../components/database/GridFilterModal";
import GridDownloadModal from "../components/database/GridDownloadModal";
import GridUploadModal from "../components/database/GridUploadModal";
import GridActions from "../components/database/GridActions";
import ModifyTable from "../components/database/modifyTable/ModifyTable";
import NoSelection from "../components/database/NoSelection";

// helpers
import {uuid} from "../helpers/uuid"
import filterRows from "../components/database/GridFilterHelpers"
import {exportToCsv} from "../helpers/fileExport"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const initialData = {
    // Cache data
    changeHistory: {},  // object, { fqtable1: {__WIO_ID1: {rowChange1} } }
    dataHistory: {},    // object, { fqtable1: { rows: [], cols: [] } }
    activeTables: {},   // object, { fqtable1: true }
    lastRowID: "w_" + uuid(),
    status: "locked"
};

const DatabasePage = (props) => {

    // contexts
    const { data, toggleMenu, setTable, clearTable } = useContext(PageContext);
    const { dbData, refreshDatabases, unsetSchema, refreshDatabasesCache } = useContext(DataContext);
    const { socket, addNotification, userProfile } = useContext(SocketContext)

    // internal state
    const [cacheData, setCacheData] = useState(initialData);
    const [openModifyModal, setModifyModal] = useState(false);
    const [tableInternal, setTableInternal] = useState({});
    const [gotInitial, setInitial] = useState(false);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [validTable, setValidTable] = useState(true);
    const [filters, setFilters] = useState({});
    const [currentFilter, setCurrentFilter] = useState({})
    const [filterTerm, setFilterTerm] = useState("")
    const [dlOpen, setDLOpen] = useState(false)
    const [ulOpen, setULOpen] = useState(false)
    const [dlFileName, setDLFileName] = useState("")

    let currentTenant = userProfile.currentTenant || ""

    let isAdmin = userProfile.hasOwnProperty("tenants") 
                    && userProfile.hasOwnProperty("currentTenant") 
                    && userProfile.tenants.hasOwnProperty(userProfile.currentTenant) 
                    && userProfile.tenants[userProfile.currentTenant] === "admin";

    //////////////////////////////
    // initial request for data //
    //////////////////////////////

    // fulfillData is a callback tied to "reqData" to process response
    const fulfillData = useCallback((d) => {
        // console.log("fulfillData", d)

        let at = cacheData.activeTables;
        at[d.fullTable] = d.tableState;

        let dh = cacheData.dataHistory;

        let dr = d.rows

        // apply changes
        for (const key in d.changes) {
            let pos = dr.findIndex(x => x.__WIO_ID === key);
            if (pos === -1) {
                // if new record, add to end
                dr.push(d.changes[key])
            } else if (d.changes[key].__WIO_CHANGED) {
                // if changed record, overwrite
                dr[pos] = d.changes[key]
            } else if (d.changes[key].__WIO_DELETED) {
                // if soft delete, add deleted property
                dr[pos] = { ...dr[pos], __WIO_DELETED: true }
            }
        }

        // add blank new row
        dr.push({ "__WIO_NEW": true, __WIO_ID: cacheData.lastRowID })

        dh[d.fullTable] = {
            rows: dr,
            cols: d.cols,
        }

        let ch = cacheData.changeHistory;
        ch[d.fullTable] = d.changes;

        // set status
        let status = d.tableState ? "editing" : "locked";

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
            activeTables: at,
            status,
        });
    }, [cacheData]);

    const requestData = useCallback((tbl, cb, skipCache) => {
        socket.emit("reqData",
            { fullTable: tbl.SchemaTable, schema: tbl.Schema, table: tbl.Table, skipCache },
            (r) => { cb(r) }
        )
    }, [socket]);

    // initial request for data when switching to a new table
    useEffect(() => {

        // only get data if a table is set and hasn't been run before
        if (Object.keys(tableInternal).length > 0 && !gotInitial && validTable && dbData.isSet) {
            requestData(tableInternal, fulfillData, false)

            // mark that this has been run
            setInitial(true)
        } 

    }, [socket, tableInternal, fulfillData, setInitial, requestData, gotInitial, validTable, dbData]);

    ///////////////
    // listeners //
    ///////////////

    // getDataChange will process changes from other clients
    const getDataChange = useCallback((d) => {
        // console.log("getDataChange", d)

        let dh = cacheData.dataHistory;
        let dr = dh[d.fullTable].rows
        let pos = dr.findIndex(x => x.__WIO_ID === d.change.__WIO_ID);
        pos === -1
            ? dr.splice(dr.length - 1, 0, d.change) // insert record before new line row
            : dr[pos] = d.change

        let ch = cacheData.changeHistory
        ch[d.fullTable][d.change.__WIO_ID] = d.change

        dh[d.fullTable].rows = dr

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
        });
    }, [cacheData])

    // getDataDelete will process deletes from other clients
    const getDataDelete = useCallback((d) => {
        // console.log("getDataDelete", d)

        let dh = cacheData.dataHistory;
        let dr = dh[d.fullTable].rows;

        let ch = cacheData.changeHistory;
        let cht = ch[d.fullTable];

        // process soft deletes
        d.softDeletes.forEach((id) => {
            let pos = dr.findIndex(x => x.__WIO_ID === id);
            if (pos > -1) {
                dr[pos] = { ...dr[pos], __WIO_DELETED: true }
            }

            cht[id] = cht[id] ? { ...cht[id], __WIO_DELETED: true } : { __WIO_ID: id, __WIO_DELETED: true }
        })

        // process permanent deletes
        d.permanentDeletes.forEach((id) => {
            let pos = dr.findIndex(x => x.__WIO_ID === id);
            if (pos > -1) {
                dr[pos] = { ...dr[pos], __WIO_PERM_DELETED: true }
            }
            delete cht[id]
        })

        dr = dr.filter(row => !(row.__WIO_PERM_DELETED))

        dh[d.fullTable].rows = dr
        ch[d.fullTable] = cht

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
        });
    }, [cacheData])
    
    // getDataRevert will process reverts
    const getDataRevert = useCallback((d) => {
        // console.log("getDataRevert", d)

        let dh = cacheData.dataHistory;
        let dr = dh[d.fullTable].rows;

        let ch = cacheData.changeHistory;
        let cht = ch[d.fullTable];

        // restore original rows
        d.originalRows.forEach((row) => {
            let pos = dr.findIndex(x => x.__WIO_ID === row.__WIO_ID);
            if ( pos > -1 ) {
                dr[pos] = row
            }

            if (cht[row.__WIO_ID]) {
                delete cht[row.__WIO_ID]
            }
        })

        // delete new rows
        d.rowsToRevert.forEach((id) => {
            let pos = dr.findIndex(x => x.__WIO_ID === id);
            if (pos > -1) {
                if ((dr[pos].__WIO_CHANGED || dr[pos].__WIO_DELETED) && dr[pos].__WIO_ID !== cacheData.lastRowID) {
                    dr[pos] = { ...dr[pos], __WIO_PERM_DELETED: true }
                }
            }
            delete cht[id]
        })

        dr = dr.filter(row => !(row.__WIO_PERM_DELETED))

        dh[d.fullTable].rows = dr
        ch[d.fullTable] = cht

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
        });
    }, [cacheData])

    
    // getActiveTable will journal the state of a table (locked vs unlocked)
    const getActiveTable = useCallback((d) => {
        // console.log("getActiveTable", d)

        let at = cacheData.activeTables;
        at[d.fullTable] = d.tableState;

        let status = d.tableState && !(d.hasOwnProperty("tableStatus"))
            ? "editing" 
            : d.hasOwnProperty("tableStatus")
                ? "saving..."
                : "locked";

        if (status === "locked") {
            requestData(tableInternal, fulfillData, false)
        }

        setCacheData({
            ...cacheData,
            activeTables: at,
            status: status,
        });
    }, [cacheData, fulfillData, tableInternal, requestData])
    
    // getCommitData will process the result of a commit
    const getCommitData = useCallback((d) => {
        // console.log("getCommitData", d)

        // set activeTables
        let at = cacheData.activeTables;
        at[d.fullTable] = d.tableState;
        
        // create new row if needed 
        let lastRowID = cacheData.lastRowID
        let newRow = { "__WIO_NEW": true, __WIO_ID: "w_" + uuid() }
        if (newRow !== null) {
            d.rows.push(newRow)
            lastRowID = newRow.__WIO_ID
        }
        
        // set rows and columns
        let dh = cacheData.dataHistory;

        dh[d.fullTable] = dh.hasOwnProperty(d.fullTable) ? dh[d.fullTable] : {};
        
        dh[d.fullTable].rows = d.rows;
        dh[d.fullTable].cols = d.cols;

        // clear history
        let ch = cacheData.changeHistory;
        ch[d.fullTable] = d.changes;

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
            activeTables: at,
            status: "locked",
            lastRowID: lastRowID,
        });
    }, [cacheData])
    
    
    // getDDLData will request data again after a ddl change
    const getDDLData = useCallback((d) => {
        // console.log("getDDLData", d)
        socket.emit("reqData",
                { fullTable: tableInternal.SchemaTable, schema: tableInternal.Schema, table: tableInternal.Table },
                (response) => { fulfillData(response) }
            )
    }, [tableInternal, fulfillData, socket])
    
    // getTableRename will automatically push to the new table
    const getTableRename = useCallback((d) => {
        // console.log("getTableRename", d)

        props.history.push(`/${currentTenant}/databases?table=${d.toTable}`);

        if (dbData.isSet) {
            refreshDatabasesCache()
        }

        setTable(d.toTableObject)
        setValidTable(true)

    }, [props.history, setTable, refreshDatabasesCache, dbData.isSet, currentTenant])

    
    // getTableDrop will unset the table if the current table is dropped elsewehere
    const getTableDrop = useCallback((d) => {
        // console.log("getTableDrop", d)
        
        refreshDatabasesCache()

        if (tableInternal.SchemaTable === d.fullTable) {
            clearTable()
        }
        
    }, [tableInternal, refreshDatabasesCache, clearTable])

    useEffect(() => {
        socket.on("emitDataChange", getDataChange);
        socket.on("emitDataDelete", getDataDelete);
        socket.on("emitDataRevert", getDataRevert);
        socket.on("emitTableStateUpdate", getActiveTable);
        socket.on("emitDataCommit", getCommitData);
        socket.on("emitDDLChange", getDDLData);
        socket.on("emitTableRename", getTableRename);
        socket.on("emitTableDrop", getTableDrop);

        return () => {
            // before the component is destroyed
            // unbind all event handlers used in this component
            socket.off("emitDataChange", getDataChange);
            socket.off("emitDataDelete", getDataDelete);
            socket.off("emitDataRevert", getDataRevert);
            socket.off("emitTableStateUpdate", getActiveTable);
            socket.off("emitDataCommit", getCommitData);
            socket.off("emitDDLChange", getDDLData);
            socket.off("emitTableRename", getTableRename);
            socket.off("emitTableDrop", getTableDrop);
        };
    }, [socket, getDataChange, getDataDelete, getDataRevert, getActiveTable, getCommitData, getDDLData, getTableRename, getTableDrop]);


    //////////////////////////////
    // react data grid handlers //
    //////////////////////////////

    // addNewRow returns a blank row if one is needed to append, otherwise null
    const addNewRow = (row) => {
        if (row.__WIO_ID === cacheData.lastRowID) {
            let newRow = { "__WIO_NEW": true, __WIO_ID: "w_" + uuid() }
            return newRow;
        }
        return null;
    }

    // setActiveTable will unlock a table for editing
    const setActiveTable = (isUnlocked) => {
        // console.log("DatabasePage.jsx - setActiveTable", isUnlocked)
        let at = cacheData.activeTables
        at[data.selectedTable.SchemaTable] = isUnlocked

        socket.emit("updTableState",
            {
                fullTable: data.selectedTable.SchemaTable,
                tableState: isUnlocked
            })
                    
        // set status
        let status = isUnlocked ? "editing" : cacheData.status;

        setCacheData({
            ...cacheData,
            activeTables: at,
            status
        })
    }
    
    // sendCommit sends a message to commit
    const sendCommit = () => {
        // console.log("DatabasePage.jsx - sendCommit")

        socket.emit("updCommit",
        {
            fullTable: data.selectedTable.SchemaTable,
            fullTableObj: data.selectedTable
        })

        setCacheData({
            ...cacheData,
            status: "saving...",
        })
    }

    // updateRows processes changes in react data grid
    const updateRows = (newRows, changedRow) => {
        // console.log("DatabasePage.jsx - updateRows", newRows, changedRow)
        let row = newRows[changedRow.indexes[0]];
        if (row.hasOwnProperty("__WIO_NEW") || row.hasOwnProperty("__WIO_CHANGED") || row.hasOwnProperty("__WIO_DELETED")) {
            let lastRowID = cacheData.lastRowID

            let newBlankRow = addNewRow(row);
            if (newBlankRow !== null) {
                newRows.push(newBlankRow)
                lastRowID = newBlankRow.__WIO_ID
            }

            let dh = cacheData.dataHistory;
            dh[data.selectedTable.SchemaTable].rows = newRows;

            let ch = cacheData.changeHistory;
            ch[data.selectedTable.SchemaTable][row.__WIO_ID] = row;

            socket.emit("updData",
                {
                    fullTable: data.selectedTable.SchemaTable,
                    change: row,
                })

            setCacheData({
                ...cacheData,
                dataHistory: dh,
                changeHistory: ch,
                lastRowID
            })
        }
    }

    // resetSelectedRows deselects all rows
    const resetSelectedRows = () => {
        setSelectedRows(new Set());
    }

    // deleteRows processes rows deleted from grid
    const deleteRows = () => {

        // update data history 
        let dh = cacheData.dataHistory;
        let dr = dh[data.selectedTable.SchemaTable].rows

        let ch = cacheData.changeHistory
        let cht = ch[data.selectedTable.SchemaTable]
        selectedRows.forEach((id) => {
            let pos = dr.findIndex(x => x.__WIO_ID === id);
            if (pos > -1) {
                dr[pos] = { ...dr[pos], __WIO_DELETED: true }
            }

            cht[id] = { ...cht[id], __WIO_DELETED: true }
        })

        // remove rows that are deleted and new
        let permanentDeletes = dr.filter(row => (row.__WIO_DELETED && row.__WIO_NEW && row.__WIO_ID !== cacheData.lastRowID))
        let permanentDeleteIDs = permanentDeletes.map(function (row) {
            return row.__WIO_ID
        });
        dr = dr.filter(row => !(row.__WIO_DELETED && row.__WIO_NEW && row.__WIO_ID !== cacheData.lastRowID))

        dh[data.selectedTable.SchemaTable].rows = dr;
        ch[data.selectedTable.SchemaTable] = cht;

        let softDeleteIDs = dr.map(function (row) {
            if (row.__WIO_DELETED) {
                return row.__WIO_ID;
            }
            return null
        });
        softDeleteIDs = softDeleteIDs.filter(v => (v !== null))

        socket.emit("deleteData",
            {
                fullTable: data.selectedTable.SchemaTable,
                softDeletes: softDeleteIDs,
                permanentDeletes: permanentDeleteIDs,
            })

        setCacheData({
            ...cacheData,
            dataHistory: dh,
            changeHistory: ch,
        })

        resetSelectedRows()
    }

    // revertRows processes gets original rows for grid
    const revertRows = () => {

        let rowsToRevert = []
        selectedRows.forEach((id) => {
            rowsToRevert.push(id);
        })

        socket.emit("revertData",
            {
                fullTable: data.selectedTable.SchemaTable,
                rowsToRevert: rowsToRevert
            })

        resetSelectedRows()
    }

    // GridActions buttonHandler
    const buttonHandler = (action) => {

        if (action === "delete") {
            deleteRows()
        }

        if (action === "revert") {
            revertRows()
        }
        
        if (action === "refresh") {
            requestData(tableInternal, fulfillData, true)
        }

        if (action === "save") {
            if (cacheData.status === "editing") {
                sendCommit()
            }            
        }

        if (action === "edit") {
            setActiveTable(true)
        }

        if (action === "modify") {
            toggleModify()
        }
        
        if (action === "download") {
            setDLFileName(tableInternal.Table)
            setDLOpen(true)
        }

        if (action === "upload") {
            setULOpen(true)
        }        

    }

    // changeTable invokes setTable and clears selected rows
    const changeTable = (v) => {
        setTable(v)
        setValidTable(true)
        resetSelectedRows()
    }   

    //////////////////////////////////////
    // Add/Modify Table/Schema handlers //
    //////////////////////////////////////

    const addTable = (def) => {
        
        socket.emit("addTable", def)
        
    }
    
    const addSchema = (schemaName) => {

        socket.emit("addSchema", {schemaName})

    }

    const modifyTable = (toBeDeleted, toBeAltered, toBeAdded, tableRename) => {
        toggleModify()

        let def = {
            toBeDeleted, 
            toBeAltered, 
            toBeAdded, 
            tableRename, 
            fullTable: data.selectedTable.SchemaTable, 
            table: data.selectedTable.Table, 
            schema: data.selectedTable.Schema
        }
        socket.emit("updTable", def)
        if (tableRename.hasOwnProperty("to")) {
            unsetSchema()
        }
        
    }

    const deleteTable = () => {
        toggleModify()

        let def = {
            fullTable: data.selectedTable.SchemaTable, 
            tableName: data.selectedTable.Table, 
            schema: data.selectedTable.Schema
        }
        socket.emit("deleteTable", def)
        unsetSchema()
        clearTable()
        
    }

    const toggleModify = () => {
        setModifyModal(!openModifyModal)
    }

    const uploadFile = (def) => {
        setULOpen(false)
        socket.emit("updUpload", def)
    }

    

    /////////////
    // filters //
    /////////////

    const openFilter = (c) => {
        // console.log("DatabasePage.jsx - openFilter", c)
        let term = ""
        if (filters.hasOwnProperty(c.key)) {
            term = filters[c.key].filterTerm
        }
        setFilterTerm(term)
        setCurrentFilter(c)
    }

    const changeFilter = (v) => {
        // console.log("DatabasePage.jsx - changeFilter", v)
        setFilterTerm(v)
    }

    const closeFilter = () => {
        // console.log("DatabasePage.jsx - closeFilter")
        setCurrentFilter({})
        setFilterTerm("")
    }

    const addFilter = () => {
        // console.log("DatabasePage.jsx - addFilter")
        filters[currentFilter.key] = {
            ...currentFilter,
            filterTerm: filterTerm || ""
        }

        setFilters(filters)
        setCurrentFilter({})
        setFilterTerm("")
    }

    const deleteFilter = (key) => {
        // console.log("DatabasePage.jsx - deleteFilter", key)

        if (filters.hasOwnProperty(key)) {
            delete filters[key]
        }

        setFilters(filters)
        setCurrentFilter({})
        setFilterTerm("")
    }

    

    //////////////
    // download //
    //////////////

    const cancelDL = () => {
        setDLOpen(false)
        setDLFileName("")
    }

    const changeDLFileName = (v) => {
        setDLFileName(v)
    }

    const downloadFile = () => {
        
        let rows = cacheData.dataHistory[data.selectedTable.SchemaTable] 
                    ? cacheData.dataHistory[data.selectedTable.SchemaTable].rows 
                    : []
        let cols = cacheData.dataHistory[data.selectedTable.SchemaTable] 
                    ? cacheData.dataHistory[data.selectedTable.SchemaTable].cols 
                    : []

        addNotification(`Downloading ${dlFileName}.csv...`, "info")

        exportToCsv(dlFileName, rows, cols)
        cancelDL()
    }


    ///////////////
    // component //
    ///////////////

    const makeTableInRoute = (stringTable) => {
        if (stringTable === null) {
            return { SchemaTable: "" }
        }

        let parsedTable = stringTable.split(".");

        let tir = {
            Schema: parsedTable[0],
            Table: parsedTable[1],
            SchemaTable: stringTable
        }

        return tir
    }

    // content displays a specific table in grid view when that table is selected
    let isUnlocked = cacheData.activeTables[data.selectedTable.SchemaTable] ? cacheData.activeTables[data.selectedTable.SchemaTable] : false

    const content = <div>
        <div className="p-4 h-12 w-full font-bold items-center flex flex-row float-left overflow-hidden sm:text-xl 2xl:text-2xl 2xl:w-5/12">
            <div>{data.selectedTable.SchemaTable}</div>
            <div className="text-sm italic font-normal pt-1 pl-2">({cacheData.status})</div>
        </div>
        <div className="h-screen-32 w-full xl:h-screen-16">
            <GridActions selectActions={selectedRows.size > 0} buttonHandler={buttonHandler} isUnlocked={isUnlocked} />

            {
                openModifyModal && !isUnlocked
                ?   
                    <ModifyTable
                        columns={cacheData.dataHistory[data.selectedTable.SchemaTable] ? cacheData.dataHistory[data.selectedTable.SchemaTable].cols : []}
                        tableName={data.selectedTable.Table}
                        schemas={dbData.databases}
                        sourceSchema={data.selectedTable.Schema}
                        modifyTable={modifyTable}
                        deleteTable={deleteTable}                        
                        toggleModal={toggleModify}
                        showDelete={isAdmin}
                    />
                : null
            }

            {
                Object.keys(currentFilter).length > 0
                    ?   <GridFilterModal 
                            filter={currentFilter}
                            filterTerm={filterTerm}
                            addFilter={addFilter}
                            changeFilter={changeFilter}
                            deleteFilter={deleteFilter}
                            closeFilter={closeFilter}
                        />
                    :   null
            }

            {
                dlOpen && tableInternal.hasOwnProperty("SchemaTable")
                ?   <GridDownloadModal 
                        table={tableInternal.SchemaTable}
                        filename={dlFileName}
                        cancel={cancelDL}
                        changeName={changeDLFileName}
                        download={downloadFile}
                    />
                :   null
            }
            

            {
                ulOpen && tableInternal.hasOwnProperty("SchemaTable")
                ?   <GridUploadModal 
                        schema={tableInternal.Schema}
                        table={tableInternal.Table}
                        columns={cacheData.dataHistory[data.selectedTable.SchemaTable] ? cacheData.dataHistory[data.selectedTable.SchemaTable].cols : []}
                        upload={uploadFile}
                        cancel={() => {setULOpen(false)}}
                    />
                :   null
            }
            
            <Grid
                updateRows={updateRows}
                fullTable={data.selectedTable.SchemaTable || ""}
                rows={filterRows(filters, cacheData.dataHistory[data.selectedTable.SchemaTable] ? cacheData.dataHistory[data.selectedTable.SchemaTable].rows : [])}
                cols={cacheData.dataHistory[data.selectedTable.SchemaTable] ? cacheData.dataHistory[data.selectedTable.SchemaTable].cols : []}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                isUnlocked={isUnlocked}
                openFilter={openFilter}
                filters={filters}
            />
        </div>
    </div>

    // handle direct routes (i.e. not clicking in to table, but going directly to url with query param)
    let isTableSet = false;
    let query = useQuery();
    let tableInRoute = makeTableInRoute(query.get("table"))

    // handle bad routes
    if (dbData.isSet && validTable) {
        let sp = dbData.databases.findIndex(s => s.schema === data.selectedTable.Schema)
        if (sp > -1) {
            let st = dbData.databases[sp].tables.findIndex(t => t.SchemaTable === data.selectedTable.SchemaTable)
            if (st === -1) {
                setValidTable(false)
            }
        } else {
            setValidTable(false)
        }
    }

    if (!validTable && Object.keys(data.selectedTable).length > 0) {
        isTableSet = true
    }

    // trigger request of data if valid table, but hasn't been set yet (due to being direct route)
    if (data.selectedTable.hasOwnProperty("SchemaTable") && data.selectedTable.SchemaTable === tableInRoute.SchemaTable && validTable) {        
        if ((!tableInternal.hasOwnProperty("SchemaTable") || data.selectedTable.SchemaTable !== tableInternal.SchemaTable)) {
            setTableInternal(data.selectedTable)
            setInitial(false)
        }
    }

    ////////////
    // return //
    ////////////

    return (
        
        <div className="flex">

            {
                dbData.isSet 
                ? validTable || !isTableSet
                    ?   <div className={"pl-20 h-screen w-full" + (data.innerOpen ? " xl:pl-20 xl:ml-96" : "")}>
                            {
                                Object.keys(data.selectedTable).length > 0
                                    ? content
                                    : <NoSelection 
                                        dbData={dbData.databases} 
                                        setTable={changeTable} 
                                        addTable={addTable} 
                                        addSchema={addSchema} 
                                        tenant={currentTenant} 
                                        />
                            }
                        </div>
                    : <Page404 />
                : Object.keys(data.selectedTable).length > 0
                    ? <PageLoading />
                    :   <div className={"pl-20 h-screen w-full" + (data.innerOpen ? " xl:pl-20 xl:ml-96" : "")}>
                            <NoSelection 
                                dbData={dbData.databases} 
                                setTable={changeTable} 
                                addTable={addTable} 
                                addSchema={addSchema} 
                                tenant={currentTenant} 
                                />
                        </div>
            }

            <InnerNavBar
                innerOpen={data.innerOpen}
                selectedTable={!isTableSet && tableInRoute.SchemaTable !== "" ? tableInRoute : data.selectedTable}
                tenant={currentTenant}
                isTableSet={isTableSet}
                dbData={dbData.databases}
                toggleMenu={toggleMenu}
                setTable={changeTable}
                clearTable={clearTable}
                refreshDatabases={refreshDatabases}
                addTable={addTable}
                addSchema={addSchema}
            />

        </div>

    );
}

export default withRouter(DatabasePage);
