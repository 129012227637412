import React from "react";

class Spinner extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-wblue" role="status">
                    {/* <span className="visually-hidden">Loading...</span> */}
                </div>
            </div>
        );
    }
}

export default Spinner;