import React from "react";
import PropTypes from "prop-types";

// helpers
import {checkRequired} from "../../helpers/formValidation"

// custom components
import Modal from "../common/modals/Modal"
import Input from "../common/form/Input.jsx"

class ChangeNameModal extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            firstname: this.props.firstname,
            lastname: this.props.lastname,
            errors: {}
        }
    }

    change = (key, e) => {
        let {firstname, lastname} = this.state;

        if (key === "firstname") {
            firstname=e.target.value
        }
        if (key === "lastname") {
            lastname=e.target.value
        }

        this.setState({
            firstname, lastname
         });
    }

    changeName = () => {
        let {firstname, lastname} = this.state;

        let errors = {};

        errors = checkRequired(errors, "firstname", firstname)
        errors = checkRequired(errors, "lastname", lastname)

        if ( Object.keys(errors).length === 0 ) {
            this.props.changeName(firstname, lastname)
        } else {
            this.setState({errors})
        }
        
    }

    render() {
        let {firstname, lastname, errors} = this.state;

        return (
            <Modal title="Change display name" 
                    cancel={this.props.onCancel}
                    enablePrimary={true}
                    primaryTitle="Change Name" 
                    primaryOnClick={ () => { this.changeName() } }
                    
            >
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <div className="my-auto font-bold px-2 w-32">First Name:</div>
                        <Input type="text" 
                            placeHolder="First Name" 
                            className="m-1" 
                            inputValue={firstname}
                            onChange={this.change.bind(this, "firstname")} 
                            error={errors.hasOwnProperty("firstname") ? errors.firstname : null}
                        />
                    </div>
                    <div className="flex flex-row">
                        <div className="my-auto font-bold px-2 w-32">Last Name:</div>
                        
                        <Input type="text" 
                            placeHolder="Last Name" 
                            className="m-1" 
                            inputValue={lastname}
                            onChange={this.change.bind(this, "lastname")} 
                            error={errors.hasOwnProperty("lastname") ? errors.lastname : null}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ChangeNameModal;

ChangeNameModal.propTypes = {
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    changeName: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
