import {
    Route,
    Redirect,
} from "react-router-dom";
import { useContext } from "react";

import { SocketContext } from "../../contexts/socketContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const AdminRoute = ({ children, ...rest }) => {
    const { userProfile } = useContext(SocketContext)

    let onboarded = userProfile.hasOwnProperty("onboarding_status") 
                    && userProfile.onboarding_status === "complete";
        
    let admin = userProfile.hasOwnProperty("tenants") 
                    && userProfile.hasOwnProperty("currentTenant") 
                    && userProfile.tenants.hasOwnProperty(userProfile.currentTenant) 
                    && userProfile.tenants[userProfile.currentTenant] === "admin";

    return (
        <Route
            {...rest}
            render={ ({ location }) => 
                onboarded && admin
                    ?   (children) 
                    :   (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location, adminFlag: userProfile.hasOwnProperty("tenants") },
                                }}
                            />
                        )
            }
        />
    );
}


export default withAuthenticationRequired(AdminRoute);