import React, { createContext, useState } from "react";

export const PageContext = createContext(null);

const initialData = {
    // Page Settings
    innerOpen: true,    // bool, is inner nav bar open?
    menuNeeded: false,  // bool, is inner nav bar needed?
    selectedTable: {},  // object, {Database: "WINTERFACE_DB", "Schema": "NEWSCHEMA", "Table": "TESTING", "SchemaTable": "NEWSCHEMA.TESTING","FQTable": "WINTERFACE_DB.NEWSCHEMA.TESTING"}
};

export const PageProvider = props => {
    const [data, setData] = useState(initialData);

    const toggleMenu = () => {
        // console.log("PageProvider - toggleMenu")

        setData({
            ...data
            , innerOpen: !data.innerOpen
        });

    }

    const setMenuNotNeeded = () => {
        // console.log("PageProvider - setMenuNotNeeded")
        setData({
            ...data
            , menuNeeded: false
        });
    }
    
    const setMenuNeeded = () => {
        // console.log("PageProvider - setMenuNeeded")
        setData({
            ...data
            , menuNeeded: true
            , innerOpen: true
            // , selectedTable: ""
        });
    }
        
    const setTable = (table) => {
        // console.log("PageProvider - setTable", table)

        // set menuNeeded to True, since only database calls this
        setData({
            ...data
            , menuNeeded: true
            , selectedTable: table
        });
    }
        
    const clearTable = () => {
        // console.log("PageProvider - clearTable")

        setData({
            ...data
            , selectedTable: {}
        });
    }
    
    
    
    return (
        <PageContext.Provider value={{ data, toggleMenu, setMenuNotNeeded, setMenuNeeded, setTable, clearTable }}>
            {props.children}
        </PageContext.Provider>
    );
};
