import React from "react";
import PropTypes from "prop-types";

// Images & Icons
import { Square, CheckSquare } from "react-feather";

class Checkbox extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <button onClick={this.props.onClick} className="flex flex-row focus:outline-none" >
                <div className="p-2">
                    { this.props.checked ? <CheckSquare data-testid="checkedSquare" /> : <Square data-testid="uncheckedSquare" /> }
                </div>
                <div className="p-2">
                    {this.props.title}
                </div>                
            </button>
        );
    }

}

export default Checkbox;

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
