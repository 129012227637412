import React from "react";
import PropTypes from "prop-types";

class Button extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <button onClick={this.props.onClick}
                className="focus:outline-none px-4 bg-gray-400 p-2 rounded w-full text-white hover:text-black hover:bg-gray-300"
                disabled={this.props.disabled || undefined}
            >
                {this.props.title}
            </button>
        );
    }

}

export default Button;

Button.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
