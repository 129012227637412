import React from "react";
import PropTypes from "prop-types";

class SettingsListContainer extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className={"mt-4" }>
                <div className="flex flex-row rounded-t-lg pl-6 sm:pl-8 w-full bg-gray-700 text-gray-100 text-medium text-left sm:text-lg xl:text-xl">
                    <div className="p-2 flex-grow">
                        {this.props.leader}
                    </div>
                    {
                        ("buttonTitle" in this.props && "buttonClick" in this.props)
                        ? <div>
                            <button onClick={this.props.buttonClick}
                                className="rounded my-1 mx-4 p-2 bg-wblue font-medium text-xs sm:text-sm focus:outline-none"
                            >
                                {this.props.buttonTitle}
                            </button>
                          </div>
                        : null
                    }
                    
                    
                </div> 
                <div className="flex flex-col w-full h-5/6 text-left text-sm sm:text-base">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default SettingsListContainer;

SettingsListContainer.propTypes = {
    leader: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonClick: PropTypes.func,
};
