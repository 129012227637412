const checkValidText = (existingErrors, source, value) => {
    const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
    if (!regex.test( value )) {
        existingErrors[source] = "Only accepts letters, numbers, and underscores"
    }
    return existingErrors
}

const checkValidEmail = (existingErrors, source, value) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regex.test( value )) {
        existingErrors[source] = "Please enter a valid email address"
    }
    return existingErrors
}

const checkRequired = (existingErrors, source, value) => {
    if (value.length < 1) {
        existingErrors[source] = "Required field"
    }
    return existingErrors
}

const checkAtLeastOne = (existingErrors, source, value, fromArray) => {
    let selectedArray = []
    fromArray.forEach((r) => {
        if (value.hasOwnProperty(r) && value[r]) {
            selectedArray.push(r)
        }
    })

    if (selectedArray.length < 1) {
        existingErrors[source] = "Must select at least one"
    }
    return existingErrors
}

export {checkValidText, checkValidEmail, checkRequired, checkAtLeastOne}