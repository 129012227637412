import React from "react";
import PropTypes from "prop-types";

// helpers
import {
    checkValidEmail, 
    checkRequired, 
    // checkAtLeastOne
} 
    from "../../helpers/formValidation"

// custom components
import Modal from "../common/modals/Modal"
// import RoleBoxes from "./RoleBoxes"
import Input from "../common/form/Input.jsx"

class AddUserModal extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedRoles: {},
            user: {
                firstname: "",
                lastname: "",
                email: "",
            },
            errors: {}
        }
    }

    selectRole = (role) => {
        let {selectedRoles} = this.state;
        if (role in selectedRoles) {
            let r = selectedRoles[role]
            selectedRoles[role] = !r;
        } else {
            selectedRoles[role] = true;
        }
        this.setState({
            selectedRoles

        })
    }

    change = (key, e) => {
        let {user} = this.state;

        user[key] = e.target.value;

        this.setState({
            user
         });
    }

    addUser = () => {
        let {selectedRoles, user} = this.state;

        let errors = {};

        errors = checkValidEmail(errors, "email", user.email)

        errors = checkRequired(errors, "email", user.email)
        errors = checkRequired(errors, "firstname", user.firstname)
        errors = checkRequired(errors, "lastname", user.lastname)

        // errors = checkAtLeastOne(errors, "roles", selectedRoles, this.props.allRoles)

        if ( Object.keys(errors).length === 0 ) {
            this.props.addUser(user, selectedRoles)
        } else {
            this.setState({errors})
        }
        
    }

    render() {
        // let {allRoles} = this.props;
        let {user, errors} = this.state;

        return (
            <Modal title="Add user" 
                cancel={this.props.onCancel}
                enablePrimary={true}
                primaryTitle="Add User" 
                primaryOnClick={ () => { this.addUser() } }
            >
                <div className="flex flex-col">
                    <div className="flex flex-row">
                        <div className="my-auto font-bold px-2 w-32">Email:</div>
                        <Input type="text" 
                            placeHolder="Email" 
                            className="" 
                            inputValue={user.email}
                            onChange={this.change.bind(this, "email")} 
                            error={errors.hasOwnProperty("email") ? errors.email : null}
                        />
                    </div>
                    <div className="flex flex-row">
                        <div className="my-auto font-bold px-2 w-32">First Name:</div>
                        <Input type="text" 
                            placeHolder="First Name" 
                            className="" 
                            inputValue={user.firstname}
                            onChange={this.change.bind(this, "firstname")} 
                            error={errors.hasOwnProperty("firstname") ? errors.firstname : null}
                        />
                    </div>
                    <div className="flex flex-row">
                        <div className="my-auto font-bold px-2 w-32">Last Name:</div>
                        
                        <Input type="text" 
                            placeHolder="Last Name" 
                            className="" 
                            inputValue={user.lastname}
                            onChange={this.change.bind(this, "lastname")} 
                            error={errors.hasOwnProperty("lastname") ? errors.lastname : null}
                        />
                    </div>
                    {/* <div className="flex flex-col">
                        <div className="font-bold p-2">Roles:</div>
                        <RoleBoxes allRoles={allRoles} selectedRoles={selectedRoles} selectRole={this.selectRole} />
                        <div className="text-red-500 p-2">{errors.hasOwnProperty("roles") ? errors.roles : null}</div>
                    </div> */}
                    
                </div>
            </Modal>
        );
    }
}

export default AddUserModal;

AddUserModal.propTypes = {
    // allRoles: PropTypes.array.isRequired,
    addUser: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
