import React from "react";
import PropTypes from "prop-types";
import DataGrid, {SelectColumn} from 'react-data-grid';

// custom components
import GridEditor from './GridEditor'
import GridEditorInteger from './GridEditorInteger'
import GridEditorDecimal from './GridEditorDecimal'
import GridEditorDate from './GridEditorDate'

// icons
import { Filter } from "react-feather"
import SortIcon from "./icons/sortIcon"
import SortIconReverse from "./icons/sortIconReverse"

class Grid extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            sortBy: "",
            direction: "ASC", 
            type: "",
        }
    }

    getColumns = () => {

        let newColumns = [];

        this.props.cols.forEach(c => {

            c.headerRenderer = p => (
                <div className="leading-7">
                    <div className="flex" >
                        <div className="flex-1 text-left">{c.name}</div>
                    </div>                
                    
                    <div className="flex flex-row-reverse" >
                        <div className="flex leading-normal">
                            <button
                                title={this.props.filters.hasOwnProperty(c.key) ? "Change Filter" : "Add Filter"}
                                className="w-7 h-7 focus:outline-none rounded-full hover:bg-gray-600 hover:text-white"
                                onClick={() => {this.props.openFilter({key: c.key, type: c.type})}}
                            >
                                {
                                    this.props.filters.hasOwnProperty(c.key)
                                        ? <Filter className="h-4 w-4 mx-auto text-wblue" />
                                        : <Filter className="h-4 w-4 mx-auto" />
                                }
                            </button>
                            <button
                                title={
                                    c.key === this.state.sortBy 
                                        ? this.state.direction === "ASC"
                                            ? "Sort Descending"
                                            : "Clear Sort"
                                        : "Sort Ascending"
                                }
                                className="w-7 h-7 focus:outline-none rounded-full hover:bg-gray-600 hover:text-white"
                                onClick={() => {this.changeSort(c)}}
                            >
                                {
                                    c.key === this.state.sortBy 
                                        ? this.state.direction === "ASC"
                                            ? <SortIcon className="h-5 w-5 pt-0.5 mx-auto text-wblue" />
                                            : <SortIconReverse className="h-5 w-5 pt-0.5 mx-auto text-wblue" />
                                        : <SortIcon className="h-5 w-5 pt-0.5 mx-auto" />
                                }
                                
                            </button>
                            
                        </div>
                        <div className="flex-auto py-1.5 italic font-normal text-xs text-left">({c.type})</div>
                    </div>
                </div>
            );

            if (this.props.isUnlocked) {
                c.editor = c.type === "Integer" 
                ? (p) => (
                    <GridEditorInteger
                        {...p}
                        rowID={p.row.__WIO_ID}
                        fullRow={p.row}
                    />
                    )
                : c.editor = c.type === "Decimal" 
                    ?   (p) => (<GridEditorDecimal
                            {...p}
                            rowID={p.row.__WIO_ID}
                            fullRow={p.row}
                        />
                        )
                    : c.editor = c.type === "Date" 
                    ?   (p) => (<GridEditorDate
                            {...p}
                            rowID={p.row.__WIO_ID}
                            fullRow={p.row}
                        />
                        )
                        : (p) => (
                            <GridEditor
                                {...p}
                                rowID={p.row.__WIO_ID}
                                fullRow={p.row}
                            />
                    )
            }

            newColumns.push(c)
        });
        
        if (this.props.isUnlocked) {
            newColumns.splice(0,0,SelectColumn);
        }
        
        
        return newColumns
    }

    // handleFill = ( {columnKey, sourceRow, targetRows} )  => {

    //     return targetRows.map(
    //         row => ({ 
    //             ...row
    //             , [columnKey]: sourceRow[columnKey] })
    //     );
    // }

    rowKeyGetter(row) {
        return row.__WIO_ID;
    }

    changeSort = (col) => {
        if (this.state.sortBy === col.key) {
            if (this.state.direction === "ASC") {
                this.setState({
                    sortBy: col.key,
                    direction: "DESC"
                })
            } else {
                this.setState({
                    sortBy: "",
                    direction: "ASC", 
                    type: ""
                })
            }
        } else {
            this.setState({
                sortBy: col.key,
                direction: "ASC",
                type: col.type
            })
        }
    }

    sortText = (a, b, key, direction) => {
        if (direction === "ASC") {
            if (a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
                return -1;
            }
            if (!a.hasOwnProperty(key) && b.hasOwnProperty(key)) {
                return 1;
            }
            if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
                return 0;
            }
            if (a[key] !== null && b[key] === null) {
                return 1;
            }
            if (a[key] === null && b[key] !== null) {
                return -1;
            }
            if (a[key] === null && b[key] === null) {
                return 0;
            }
            return a[this.state.sortBy].localeCompare(b[this.state.sortBy])
        } else {
            if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) {
                return 1;
            }
            if (!b.hasOwnProperty(key) && a.hasOwnProperty(key)) {
                return -1;
            }
            if (!b.hasOwnProperty(key) && !a.hasOwnProperty(key)) {
                return 0;
            }
            if (b[key] !== null && a[key] === null) {
                return 1;
            }
            if (b[key] === null && a[key] !== null) {
                return -1;
            }
            if (b[key] === null && a[key] === null) {
                return 0;
            }
            return b[this.state.sortBy].localeCompare(a[this.state.sortBy])
        }
    }

    sortedRows = () => {
        let {rows} = this.props;

        if (this.state.sortBy === "") {
            return rows;
        }

        let sortedRows = [...rows];

        switch (this.state.type) {
            case "Date":
            case "Text":
                sortedRows = sortedRows.sort((a, b) => (this.sortText(a, b, this.state.sortBy, this.state.direction)))
                break;
            case "Integer":
            case "Decimal":
                sortedRows = this.state.direction === "ASC" 
                                ? sortedRows.sort((a, b) => a[this.state.sortBy] - b[this.state.sortBy])
                                : sortedRows.sort((a, b) => b[this.state.sortBy] - a[this.state.sortBy]);
                break;
            default:

        }

        return sortedRows

    }

    render() {
        
        return (
            <DataGrid
                rowKeyGetter={this.rowKeyGetter}
                columns={this.getColumns()}
                rows={this.sortedRows()}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                    minWidth: 120
                }}
                selectedRows={this.props.selectedRows}
                onSelectedRowsChange={this.props.setSelectedRows}
                onRowsChange={this.props.updateRows}
                enableFilterRow={true}
                style={{height:"100%"}}
                className="h-screen-32"
                headerRowHeight={58}
                // sortColumn={sortColumn}
                // onSort={this.handleSort}
                // sortDirection={sortDirection}
                // onFill={this.handleFill}
                // summaryRows={summaryRows}
                // onPaste
                // onScroll
                rowClass={
                    row => 
                    row.__WIO_CHANGED && row.__WIO_DELETED 
                        ? "font-bold text-red-400 line-through" 
                        : row.__WIO_CHANGED
                            ? "font-bold text-wblue-dark dark:text-wblue" 
                            : row.__WIO_DELETED
                                ? "text-red-400 line-through" 
                                : undefined
                }
                
                
            />
        );
    }
}

export default Grid;

Grid.propTypes = {
       fullTable: PropTypes.string.isRequired,
       selectedRows: PropTypes.object.isRequired,
       updateRows: PropTypes.func.isRequired,
       setSelectedRows: PropTypes.func.isRequired,
       rows: PropTypes.array.isRequired,
       cols: PropTypes.array.isRequired,
};
