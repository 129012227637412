import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const TableIcon = (props) => {
    return (
    <svg className={props.className} viewBox="0 0 16 16">
        <path d="M0 3v11h16v-11h-16zM6 10v-2h4v2h-4zM10 11v2h-4v-2h4zM10 5v2h-4v-2h4zM5 5v2h-4v-2h4zM1 8h4v2h-4v-2zM11 8h4v2h-4v-2zM11 7v-2h4v2h-4zM1 11h4v2h-4v-2zM11 13v-2h4v2h-4z"></path>
    </svg>
    )
}

class TableItem extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {
        let fullTableName = this.props.schema + "." + this.props.table;

        return (
            <li>
                <NavLink
                    to={`/${this.props.tenant}/databases?table=${fullTableName}` }
                    className={"block px-8 py-1 hover:text-wblue-dark dark:hover:text-wblue-light" + (this.props.active ? " text-wblue-dark bg-gray-100 dark:text-gray-100 dark:bg-wdarkgrey" : "") }
                    onClick={(e) => this.props.setTable(this.props.tableObject, e)}
                >
                    <TableIcon className="inline-block h-4 mb-1 fill-current" />
                    <span className="inline-block px-3" >{this.props.table}</span>     
                </NavLink>
            </li>
        );
    }
}

export default TableItem;

TableItem.propTypes = {
    tenant: PropTypes.string.isRequired,
    schema: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    tableObject: PropTypes.object.isRequired,
    setTable: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
};
