import React from "react";
import PropTypes from "prop-types";

// Icons
import { RefreshCw } from 'react-feather';

class Status extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <div className="flex flex-row justify-center">
                {
                    this.props.status === "Active" 
                        ? <div className="font-bold text-green-500 text-center">{this.props.status}</div>
                        : this.props.status === "Error" 
                            ? <div className="font-bold text-red-500 text-center">{this.props.status}</div>
                            : <div className="font-bold text-center">{this.props.status}</div>
                }
                <button  
                    className="ml-2 hover:text-wblue-light"
                    onClick={()=>{this.props.test()}}
                    data-testid="refreshButton"
                >
                    <RefreshCw className="inline-block w-4 h-4" />
                </button>
                
            </div>
            
        );
    }

}

export default Status;

Status.propTypes = {
    status: PropTypes.string.isRequired,
    test: PropTypes.func.isRequired,
};
