import React from 'react';

const WinterfaceFull = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.06 97.69" className={className}>
        <title>Winterface</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    d="M146.25,38.38,157.12,63.3l11.49-27.75L180,63.3l11-24.92H198l-18.2,39.23L168.52,50.22,157.21,77.61l-18-39.23Z" />
                <path d="M207.93,20a4.51,4.51,0,1,1-4.51,4.5A4.52,4.52,0,0,1,207.93,20Zm3.18,18.38V75.05h-6.37V38.38Z" />
                <path
                    d="M223.12,38.38h6.37v4.24A12.91,12.91,0,0,1,240,37.49c4.33,0,8.31,1.77,10.52,5,1.85,2.74,2.12,5.74,2.12,9.81V75.05h-6.37V52.51c0-2.21-.17-4.77-1.67-6.71a7.21,7.21,0,0,0-6-2.65A8,8,0,0,0,232.14,46c-2.48,2.91-2.65,7.24-2.65,9.89V75.05h-6.37Z" />
                <path d="M271.19,44.21V75.05h-6.36V44.21h-3.89V38.38h3.89V25.12h6.36V38.38h6.36v5.83Z" />
                <path
                    d="M316.08,65.68a21.91,21.91,0,0,1-4.51,5.92,17.78,17.78,0,0,1-12.19,4.51c-8.84,0-18.12-5.57-18.12-19.18,0-11,6.72-19.44,17.76-19.44,7.16,0,11.67,3.54,13.88,6.72s3.62,8.92,3.44,13.78H287.8c.09,7.6,5.3,12.46,11.66,12.46A11.12,11.12,0,0,0,307,67.8a14.48,14.48,0,0,0,3.8-5Zm-6.28-13c-1.06-5.74-5.39-9.54-10.78-9.54a11.14,11.14,0,0,0-10.78,9.54Z" />
                <path
                    d="M326.06,38.38h6.36v4c.62-1,3.36-4.86,8.31-4.86a13.5,13.5,0,0,1,5.65,1.5l-3,5.75a6.58,6.58,0,0,0-3.71-1.24,6.15,6.15,0,0,0-5.13,2.3c-1.06,1.41-2.12,3.89-2.12,9.9V75.05h-6.36Z" />
                <path
                    d="M360.61,44.21V75.05h-6.37V44.21h-3.53V38.38h3.53v-16c0-3.36.27-7.6,3.54-10.34a11.43,11.43,0,0,1,7.33-2.38,12.47,12.47,0,0,1,4.51.79v6.19a11.58,11.58,0,0,0-4.15-.8,4.47,4.47,0,0,0-4.07,1.95c-.79,1.32-.79,3.36-.79,5.3V38.38h9v5.83Z" />
                <path
                    d="M403.37,38.38h6.36V75.05h-6.36V69.92a15.46,15.46,0,0,1-12.55,6.19c-9.27,0-17.84-6.54-17.84-19.44,0-12.64,8.48-19.18,17.84-19.18a15.18,15.18,0,0,1,12.55,6.1ZM379.51,56.67c0,7.86,4.42,13.78,12,13.78,7.78,0,12.2-6.45,12.2-13.69,0-9.46-6.54-13.61-12.2-13.61C385.26,43.15,379.51,47.74,379.51,56.67Z" />
                <path
                    d="M450.47,48.63A14.81,14.81,0,0,0,439,43.15c-6.8,0-13,4.77-13,13.69s6.36,13.61,12.9,13.61a15.65,15.65,0,0,0,11.84-5.74v7.6a19.62,19.62,0,0,1-11.66,3.8c-11.4,0-19.62-8.13-19.62-19.18,0-11.22,8.31-19.44,19.62-19.44A19.41,19.41,0,0,1,450.47,41Z" />
                <path
                    d="M493,65.68a21.91,21.91,0,0,1-4.51,5.92,17.78,17.78,0,0,1-12.19,4.51c-8.84,0-18.12-5.57-18.12-19.18,0-11,6.72-19.44,17.76-19.44,7.16,0,11.67,3.54,13.88,6.72s3.62,8.92,3.44,13.78H464.69c.09,7.6,5.3,12.46,11.66,12.46a11.16,11.16,0,0,0,7.52-2.65,14.57,14.57,0,0,0,3.79-5Zm-6.28-13c-1.06-5.74-5.39-9.54-10.78-9.54a11.14,11.14,0,0,0-10.78,9.54Z" />
                <path d="M507,67.09a4.42,4.42,0,1,1-4.41,4.42A4.49,4.49,0,0,1,507,67.09Z" />
                <path d="M526.28,20a4.51,4.51,0,1,1-4.51,4.5A4.52,4.52,0,0,1,526.28,20Zm3.18,18.38V75.05h-6.37V38.38Z" />
                <path
                    d="M539.18,56.84c0-11.13,8.21-19.35,19.44-19.35s19.44,8.22,19.44,19.35-8.31,19.27-19.44,19.27S539.18,68,539.18,56.84Zm6.53,0c0,9,6.37,13.61,12.91,13.61s12.9-4.68,12.9-13.61-6.19-13.69-12.9-13.69S545.71,47.92,545.71,56.84Z" />
                <rect fill="#cccbcb" style={{stroke:"#000", strokeMiterlimit:10, strokeWidth: 4}} x="73.32" y="12.57" width="43.1" height="34.13"
                    transform="translate(164.29 -41.51) rotate(116.95)" />
                <rect fill="#cccbcb" style={{stroke:"#000", strokeMiterlimit:10, strokeWidth: 4}} x="53.79" y="50.99" width="43.1" height="34.13"
                    transform="translate(170.15 31.74) rotate(116.95)" />
                <circle fill="#48c1c4" style={{stroke:"#000", strokeMiterlimit:10, strokeWidth: 4}} cx="60.9" cy="51.36" r="30.22" />
                <rect fill="#cccbcb" style={{stroke:"#000", strokeMiterlimit:10, strokeWidth: 4}} x="6.11" y="12.57" width="43.1" height="34.13"
                    transform="translate(41.54 -8.46) rotate(63.05)" />
                <rect fill="#cccbcb" style={{stroke:"#000", strokeMiterlimit:10, strokeWidth: 4}} x="25.65" y="50.99" width="43.1" height="34.13"
                    transform="translate(86.48 -4.86) rotate(63.05)" />
            </g>
        </g>
    </svg>

  )
}

export default WinterfaceFull;
