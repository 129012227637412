import React from "react";

// custom components
import Card from "../common/Card.jsx"

const OnboardingContainer = (props) => {

    return (
        
        <Card title={props.title}>
            <div>
                <div className="p-2">
                    {props.description}
                    {
                        props.doclink
                        ? <a href={props.doclink} className="text-wblue">{props.doclinkText}</a>
                        : null
                    }
                </div>
                {props.children}
            </div>
        </Card>
    )

};

export default OnboardingContainer;