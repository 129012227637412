import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";


class StandardLink extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <li >
                <NavLink exact to={this.props.to}
                    title={this.props.title}
                    className="flex items-center p-3 border-b border-gray-900 hover:text-wblue"
                    activeClassName="bg-gray-900 text-wblue"
                    onClick={(e) => this.props.setMenu(e)}
                >
                    {this.props.children}
                </NavLink>
            </li>
        );
    }
}

export default StandardLink;

StandardLink.propTypes = {
        to: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        setMenu: PropTypes.func.isRequired,
};
