import React from "react";
import PropTypes from "prop-types";

class ButtonDanger extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <button className={"bg-red-400 text-black rounded p-2 mx-auto w-full " + (this.props.disabled ? "cursor-not-allowed" : "") }
                disabled={this.props.disabled || undefined}
                onClick={this.props.onClick}>
                <div className={this.props.className || ""}>
                    {this.props.title}
                </div>                
            </button>
        );
    }

}

export default ButtonDanger;

ButtonDanger.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
