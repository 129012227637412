import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ButtonPrimary from "../common/button/ButtonPrimary"

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <ButtonPrimary onClick={() => loginWithRedirect()} title="Log In" />
  
};

export default LoginButton;