import React from "react";
import PropTypes from "prop-types";

class SettingsContainer extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            
            <div className="flex p-2 w-0 w-full flex-col sm:flex-row">
                <div className="mx-auto text-center w-5/6 sm:px-6 my-auto sm:flex-grow sm:text-left">
                    <div className="font-bold">{this.props.setting}</div>
                    <div>{this.props.settingDesc}</div>
                </div>
                <div className="flex-grow mx-auto w-5/6 px-2 sm:w-1/2">{this.props.children}</div>
            </div>
        );
    }
}

export default SettingsContainer;

SettingsContainer.propTypes = {
    setting: PropTypes.string,
    settingDesc: PropTypes.string,
};
