import React from "react";
import PropTypes from "prop-types";

// helpers
import {checkRequired} from "../../helpers/formValidation"

// custom components
import Modal from "../common/modals/Modal";
import Button from "../common/button/Button";
// import Status from "../common/status/Status";
import OnboardingOption from "../login/OnboardingOption.jsx"



class DBConfiguration extends React.Component {

    constructor(props) {
        super(props);

        let {config} = this.props;
        config.password = "";

        this.state = {
            config,
            status: "Click to test",
            validationErrors: {}
          }
    }

    validateChange = (source, value) => {
        let {validationErrors} = this.state;

        // delete any existing error
        if (validationErrors.hasOwnProperty(source)) {
            delete validationErrors[source]
        }

        return validationErrors
    }

    change = (source, value) => {
        let {validationErrors, config} = this.state;
        config[source] = value;
        validationErrors = this.validateChange(source, value)

        if (validationErrors.hasOwnProperty("connection")) {
            delete validationErrors.connection
        }

        this.setState({
            validationErrors, 
            config,
            status: "Click to test"
        })
    }

    blur = (source, value) => {
        let {validationErrors} = this.state;
        // delete any existing error
        if (validationErrors.hasOwnProperty(source)) {
            delete validationErrors[source]
        }

        // ANY REQUIRED
        validationErrors = checkRequired(validationErrors, source, value)

        
        this.setState({
            validationErrors
        })
    }

    checkAll = (validationErrors, config) => {

        validationErrors = checkRequired(validationErrors, "sfinstance", config.sfinstance || "")
        validationErrors = checkRequired(validationErrors, "sfrole", config.sfrole || "")
        validationErrors = checkRequired(validationErrors, "sfuser", config.sfuser || "")
        validationErrors = checkRequired(validationErrors, "password", config.password || "")
        validationErrors = checkRequired(validationErrors, "sfwarehouse", config.sfwarehouse || "")
        validationErrors = checkRequired(validationErrors, "sfdb", config.sfdb || "")
        
        if (this.state.status === "Click to test") {
            validationErrors.connection = "Test connection before continuing"
        }

        return validationErrors
    }
    
    fulfillTest = (d) => {
        
        if (d.status === "Success") {
            
            this.setState({
                status: d.status, 
                validationErrors: {}
            })
        } else {
            this.setState({
                status: "Error - Test Again", 
                validationErrors: {...this.state.validationErrors, connection: d.error}
            })
        }
    }

    test = () => {
        let {validationErrors, config} = this.state;
        
        validationErrors = this.checkAll(validationErrors, config)
        if (validationErrors.hasOwnProperty("connection")) {
            delete validationErrors.connection
        }

        if (Object.keys(validationErrors).length > 0) {
            this.setState({
                validationErrors: {...validationErrors, connection: "Please fix errors before testing"}
            })
            return
        }

        let data = {
            "UserName": config.sfuser,
            "Password": config.password,
            "Account": config.sfinstance + ".snowflakecomputing.com",
            "Database": config.sfdb,
            "Role": config.sfrole,
            "Warehouse": config.sfwarehouse,
            testExisting: false,
        }

        this.props.test(data, this.fulfillTest)
        this.setState({validationErrors, status: "Testing..."})

    }

    submit = () => {
        let {validationErrors, config} = this.state;

        validationErrors = this.checkAll(validationErrors, config)
        
        if (this.state.status !== "Success") {
            validationErrors.connection = "Test connection before continuing"
        }

        if (Object.keys(validationErrors).length > 0) {
            this.setState({validationErrors})
        } else {
            this.props.submit(config, () => {})
        }

    }

    render() {
        let {validationErrors, config} = this.state
        
        return (
            <Modal title="Configure Snowflake Instance" 
                cancel={this.props.cancel}
                enablePrimary={true}
                primaryTitle="Save" 
                primaryOnClick={ () => { this.submit() } }
            >
                    <div className="flex flex-col sm:flex-row">
                        <OnboardingOption 
                            title="Snowflake Account"
                            placeholder="myinstance.us-east-1"
                            outerClass="w-full"
                            error={
                                validationErrors.hasOwnProperty("sfinstance") 
                                ? validationErrors.sfinstance 
                                : undefined
                            }
                            inputValue={config.sfinstance || ""}
                            onChange={(e) => this.change("sfinstance", e.target.value)}
                            onBlur={(e) => this.blur("sfinstance", e.target.value)}
                        />
                        <code className="mb-2 sm:mt-11 px-2 w-32">.snowflakecomputing.com</code>
                    </div>
                    <OnboardingOption 
                        title="Snowflake Role"
                        placeholder="WINTERFACE_ROLE"
                        error={
                            validationErrors.hasOwnProperty("sfrole") 
                            ? validationErrors.sfrole 
                            : undefined
                        }
                        inputValue={config.sfrole || ""}
                        onChange={(e) => this.change("sfrole", e.target.value)}
                        onBlur={(e) => this.blur("sfrole", e.target.value)}
                    />
                    <OnboardingOption 
                        title="Snowflake User"
                        placeholder="WINTERFACE_USER"
                        error={
                            validationErrors.hasOwnProperty("sfuser") 
                            ? validationErrors.sfuser 
                            : undefined
                        }
                        inputValue={config.sfuser || ""}
                        onChange={(e) => this.change("sfuser", e.target.value)}
                        onBlur={(e) => this.blur("sfuser", e.target.value)}
                    />
                    <OnboardingOption 
                        title="Snowflake Password"
                        type="password" 
                        placeholder="Snowflake Password"
                        error={
                            validationErrors.hasOwnProperty("password") 
                            ? validationErrors.password 
                            : undefined
                        }
                        inputValue={config.password || ""}
                        onChange={(e) => this.change("password", e.target.value)}
                        onBlur={(e) => this.blur("password", e.target.value)}
                    />
                    <OnboardingOption 
                        title="Snowflake Warehouse"
                        placeholder="WINTERFACE_WH"
                        error={
                            validationErrors.hasOwnProperty("sfwarehouse") 
                            ? validationErrors.sfwarehouse 
                            : undefined
                        }
                        inputValue={config.sfwarehouse || ""}
                        onChange={(e) => this.change("sfwarehouse", e.target.value)}
                        onBlur={(e) => this.blur("sfwarehouse", e.target.value)}
                    />
                    <OnboardingOption 
                        title="Default Database"
                        placeholder="WINTERFACE_DB"
                        error={
                            validationErrors.hasOwnProperty("sfdb") 
                            ? validationErrors.sfdb 
                            : undefined
                        }
                        inputValue={config.sfdb || ""}
                        onChange={(e) => this.change("sfdb", e.target.value)}
                        onBlur={(e) => this.blur("sfdb", e.target.value)}
                    />
                    <div className="flex flex-row">
                        <div className="p-1 w-64">
                            <Button onClick={()=>{this.test()}} title={this.state.status} />
                            {
                                validationErrors.hasOwnProperty("connection") 
                                    ?   <span className="text-red-500 text-sm">
                                            {validationErrors.connection}
                                        </span>
                                    :   null
                            }

                        </div>
                    </div>
                    
                    <div className="mt-4 p-1 w-full font-bold">
                        {
                            Object.keys(validationErrors).length > 0
                            ? <div className="pb-2 text-red-500">Please correct above errors before saving.</div>
                            : null
                        }
                    </div>
            </Modal>
        );
    }
}

export default DBConfiguration;

DBConfiguration.propTypes = {
    config: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    test: PropTypes.func.isRequired,
};
