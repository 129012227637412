import React from "react";
import PropTypes from "prop-types";

class Avatar extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div>
                {
                    ("image" in this.props && typeof this.props.image !== "undefined") 
                        ? <img
                                className="h-10 w-10 rounded-full object-cover mx-auto"
                                data-testid={"avatarImage"}
                                src={this.props.image}
                                alt={this.props.alt}
                            />
                        : 
                            <div className="py-2.5 h-10 w-10 rounded-full object-cover mx-auto tracking-widest text-center text-white text-sm bg-gray-600 dark:bg-gray-400"
                            data-testid={"avatarInitials"}
                            >
                                {this.props.alt}
                            </div>
                }
                
            </div>
        );
    }
}

export default Avatar;

Avatar.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
};
