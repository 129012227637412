import React from "react";
import PropTypes from "prop-types";

// custom components
import SettingsListItem from "../common/settingsList/SettingsListItem"

// Images & Icons
import { X, Star } from "react-feather";

class TenantItemManage extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {
        
        return (
            <SettingsListItem>
                <div className="p-4 w-0 invisible sm:w-1/12 sm:visible"></div>
                <div className="flex-grow py-5">
                    <div className="font-semibold">
                        {this.props.tenantName}
                        {
                            this.props.primaryTenantFlag 
                                ? <span className="italic text-sm font-normal">&nbsp;(default)</span> 
                                : null
                        }
                    </div>
                </div>

                {
                    this.props.primaryTenantFlag
                    ?   <div className="py-5 px-2 ">
                            <button className="focus:outline-none text-wblue"
                                    title="Default Instance"
                                    onClick={()=>{}}
                            >
                                <Star /> 
                            </button>
                        </div>
                    :   <div className="py-5 px-2 ">
                            <button className="focus:outline-none hover:text-wblue"
                                    title="Set Default"
                                    onClick={()=>{
                                        this.props.setPrimaryTenant(this.props.tenantName)
                                    }}
                            >
                                <Star /> 
                            </button>
                        </div>
                }

                <div className="py-5 px-2 md:pr-10">
                    <button className="focus:outline-none hover:text-red-600 text-red-500"
                            title="Leave Instance"
                            onClick={()=>{this.props.leaveTenant(this.props.tenantName)}}
                    >
                        <X /> 
                    </button>
                </div>
            </SettingsListItem>
        );
    }
}

export default TenantItemManage;

TenantItemManage.propTypes = {
    tenantName: PropTypes.string.isRequired,
    primaryTenantFlag: PropTypes.bool.isRequired,
    leaveTenant: PropTypes.func.isRequired,
    setPrimaryTenant: PropTypes.func.isRequired,
};
