import React from "react";
import PropTypes from "prop-types";

import DatabaseIcon from './DatabaseIcon'


class DatabaseItem extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        const tables = this.props.expanded
            ? this.props.children 
            : null

        return (
            <li>
                <button
                    className="flex p-1 ml-2 my-2 h-7 focus:outline-none hover:text-wblue-dark dark:hover:text-wblue-light"
                    onClick={(e) => this.props.expand(this.props.schema, e)}
                >
                    <DatabaseIcon className="inline-block w-3 pt-1" />
                    <span className="inline-block px-3 font-medium" >{this.props.schema}</span>
                </button>
                <ul className={"bg-white dark:bg-gray-600"}>
                    {tables}                    
                </ul>
            </li>
        );
    }
}

export default DatabaseItem;

DatabaseItem.propTypes = {
        schema: PropTypes.string.isRequired,
        expand: PropTypes.func.isRequired,
        expanded: PropTypes.bool.isRequired,
};
