import React from "react";
import PropTypes from "prop-types";

// custom components
import SettingsListItem from "../common/settingsList/SettingsListItem"
import Avatar from "../common/Avatar"

// Images & Icons
import { Settings } from "react-feather";

class UserItem extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {
        let {user} = this.props;

        // // make # of roles plural where appropriate
        // let roleText = " role"
        // roleText = roleText + (user.roles.length === 1 ? "" : "s")
        // roleText = user.roles.length.toString() + roleText;

        return (
            <SettingsListItem>
                <div className="p-4 w-0 invisible sm:w-2/12 sm:visible">
                    <Avatar image={user.a0_user.picture || undefined} alt={user.initials} />
                </div>
                <div className="flex-grow py-3">
                    <div className="font-semibold">{user.firstname + " " + user.lastname} {user.status === "Invited" ? <span className="italic text-sm font-normal">(invited)</span> : null}</div>
                    <div className="text-xs sm:text-sm">{user.email}</div>
                    {/* <div className="">
                        <button 
                            onClick={this.props.onRolesClick.bind(this,user.id)}
                            className="font-medium text-wblue text-xs sm:text-sm focus:outline-none hover:text-wblue-dark"
                        >
                                {roleText}
                        </button>
                    </div> */}
                </div>
                <div className="py-5 px-2 md:px-10">
                    <button className="focus:outline-none hover:text-wblue" 
                            data-testid="settingButton"
                            onClick={this.props.onSettingClick.bind(this,user)}
                    >
                        <Settings />
                    </button>
                </div>
            </SettingsListItem>
        );
    }
}

export default UserItem;

UserItem.propTypes = {
    user: PropTypes.object.isRequired,
    onRolesClick: PropTypes.func.isRequired,
    onSettingClick: PropTypes.func.isRequired,
};
