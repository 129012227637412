import { quicktype, InputData, jsonInputForTargetLanguage } from "quicktype-core"

const parseTypes = (colArray, def) => {
    let finalDefs = {} 
    colArray.forEach( (c) => {
        let type = "Text"
        if (def.hasOwnProperty(c)) {
            // test for nullable types
            if (def[c].hasOwnProperty("anyOf") && Array.isArray(def[c].anyOf) && def[c].anyOf.length > 0) {
                // check if date
                if (def[c].anyOf[0].hasOwnProperty("type") && def[c].anyOf[0].hasOwnProperty("format") && def[c].anyOf[0].format === "date") {
                    type = "Date"
                }
                // check if integer
                if (def[c].anyOf[0].hasOwnProperty("type") && def[c].anyOf[0].type === "integer") {
                    type = "Integer"
                }
                // check if decimal
                if (def[c].anyOf[0].hasOwnProperty("type") && def[c].anyOf[0].type === "number") {
                    type = "Decimal"
                }                    
            } else if (def[c].hasOwnProperty("format") && def[c].format === "date") {
                type = "Date"
            } else if (def[c].hasOwnProperty("type") && def[c].type === "integer") {
                type = "Integer"
            } else if (def[c].hasOwnProperty("type") && def[c].type === "number") {
                type = "Decimal"
            }
            finalDefs[c] = type
        }            
    })

    return finalDefs
}

const quicktypeJSON = async (typeName, jsonString) => {
    const jsonInput = jsonInputForTargetLanguage("schema");

    await jsonInput.addSource({
        name: typeName,
        samples: [jsonString],
    });

    const inputData = new InputData();
    inputData.addInput(jsonInput);

    return await quicktype({
        inputData,
        lang: "schema",
        rendererOptions: {
            'just-types': true,
            // 'runtime-typecheck': false
        }
    });
}


const validateColumn = (columns, columnName, dataType, validationErrors, activeColumn) => {
    let errors = {}

    const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
    if (!regex.test(columnName)) {

        errors["name"] = "Name can only contain letters, numbers, and underscores"
    }

    if (columnName.length < 1) {
        errors["name"] = "Column name must be filled in"
    }

    let pos = columns.findIndex(c => c.name.toLowerCase() === columnName.toLowerCase());
    if (pos > -1 && pos !== activeColumn) {
        errors["name"] = "Column names must be unique"
    }

    let validTypes = ["Text", "Integer", "Decimal", "Date"]
    let p = validTypes.findIndex(t => t === dataType);

    if (p === -1) {
        errors["type"] = "Data type is invalid"
    }

    if (validationErrors.hasOwnProperty("table")) {
        errors.table = validationErrors.table
    }

    return errors
}

const csvError = (error, file) => {
    // console.log("error", error)
    let validationErrors = {
        file: `Could not parse file: ${error.message}`
    }
    return {
        success: false,
        validationErrors
    }
}


const csvComplete = (results, file) => {
    
    let validationErrors = {}

    // handle parsing errors
    if (results.errors.length > 0) {
        validationErrors["file"] = ""
        results.errors.forEach( (e) => {
            validationErrors["file"] += `Row ${e.row}: ${e.message} \n`
        })
        return {
            success: false,
            validationErrors
        }
    }

    // handle no data
    if (results.data.length === 0) {
        validationErrors["file"] = "Your file must have at least one row of data."
        return {
            success: false,
            validationErrors
        }
    }

    // parse types if there is data
    let r = quicktypeJSON("Atype", JSON.stringify(results.data))

    const getResult = async () => {
        const v = await r;
        try {
            let obj = JSON.parse(v.lines.join(" "))

            if (obj.hasOwnProperty("definitions") 
                && obj.definitions.hasOwnProperty("AtypeElement") 
                && obj.definitions.AtypeElement.hasOwnProperty("required")
                && obj.definitions.AtypeElement.hasOwnProperty("properties")
            ) {
                let defs = parseTypes(obj.definitions.AtypeElement.required, obj.definitions.AtypeElement.properties)

                // create columns object
                let columns = []
                results.meta.fields.forEach((c, i) => {
                    let newC = {}
                    if (defs.hasOwnProperty(c)) {
                        newC["name"] = c.toUpperCase()
                        newC["key"] = c.toUpperCase()
                        newC["type"] = defs[c]
                        columns.push(newC)
                    } else {
                        throw new Error(`Could not parse type for column ${c}.`)
                    }
                });

                // validate columns
                columns.forEach((c, i) => {
                    let err = validateColumn(columns, c.name, defs[c.name], {}, i)
                    if (err.hasOwnProperty("name")) {
                        throw new Error(`Issue with column ${i+1}: "${c.name}" - ${err.name}`)
                    }
                });

                // process rows
                let rows = [];
                results.data.forEach((r, i) => {
                    let newRow = {}
                    for (let c in r) {
                        newRow[c.toUpperCase()] = r[c]
                    }
                    newRow.__WIO_ID = i
                    newRow.__WIO_NEW = true
                    rows.push(newRow)
                })

                // success, return                    
                return {
                    success: true,
                    validationErrors,
                    columns,
                    rows,
                    file
                }
                
            } else {
                throw new Error("Unable to parse this file, please check if valid.")
            }
        }
        catch(e) {
            validationErrors["file"] = e.toString()
            return {
                success: false,
                validationErrors
            }
        }
    };
      

    return getResult();    
    
}

const backfillRows = (results, file, columns, originalRows) => {

    // transform column array to map for easier lookups
    let coltypes = {}

    columns.forEach((col) => {
        coltypes[col.name] = col.type
    })

    // backfill row with untyped results if text column
    results.data.forEach((r, i) => {
        
        for (let c in r) {
            if (coltypes[c.toUpperCase()] === "Text") {
                originalRows[i][c.toUpperCase()] = r[c]
            }            
        }

    })
                   
    return {
        success: true,
        rows: originalRows
    }

}


export { validateColumn, csvComplete, csvError, backfillRows }