import React from "react";
import PropTypes from "prop-types";

class Input extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <div className={"flex flex-col " + (this.props.outerClass || "")}>
                <input type={this.props.type || "text"}
                                placeholder={this.props.placeHolder}
                                className={"p-2 h-10 m-1 rounded-md ring-2 ring-gray-200 text-black focus:outline-none focus:ring-wblue " + this.props.className || ""} 
                                value={this.props.inputValue}
                                onChange={this.props.onChange} 
                                onBlur={this.props.onBlur || undefined} 
                                disabled={this.props.disabled || undefined}
                                
                />
                {
                    this.props.error
                        ? <span className="pl-2 text-red-500 text-sm">{this.props.error}</span>
                        : null
                }
            </div>
        );
    }

}

export default Input;

Input.propTypes = {
    className: PropTypes.string,
    outerClass: PropTypes.string,
    error: PropTypes.string,    
    placeHolder: PropTypes.string.isRequired,
    inputValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
