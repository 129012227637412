import React from 'react';

const DatabaseIcon = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.22 76.05" className={className}>
      <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
              <ellipse fill="#1f2937" cx="28.11" cy="7.57" rx="21.34" ry="7.57" />
              <path fill="#48c1c4"
                  d="M56.22,65.72h0a2.06,2.06,0,0,1,0,.35c0,5.51-12.59,10-28.11,10S0,71.58,0,66.07a2.06,2.06,0,0,1,0-.35H0V9.05c0,5.51,12.58,10,28.11,10s28.11-4.46,28.11-10Z" />
              <path fill="#fff"
                  d="M55.39,19.91v6.14c0,5.35-12.22,9.68-27.28,9.68S.84,31.4.84,26.05V19.91c0,5.35,12.21,9.68,27.27,9.68S55.39,25.26,55.39,19.91Z" />
              <path fill="#fff"
                  d="M55.39,34.64v6.14c0,5.35-12.22,9.68-27.28,9.68S.84,46.13.84,40.78V34.64c0,5.35,12.21,9.68,27.27,9.68S55.39,40,55.39,34.64Z" />
              <path fill="#fff"
                  d="M55.39,49.21v6.14C55.39,60.7,43.17,65,28.11,65S.84,60.7.84,55.35V49.21c0,5.35,12.21,9.68,27.27,9.68S55.39,54.56,55.39,49.21Z" />
          </g>
      </g>
  </svg>
  )
}

export default DatabaseIcon;
