import React, { useState, useEffect, useContext } from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// contexts
import { SocketContext } from "./contexts/socketContext";

// Pages
import HomePage from "./pages/HomePage";
import DatabasePage from "./pages/DatabasePage";
import PaymentPage from "./pages/PaymentPage";
import SettingsPage from "./pages/SettingsPage";
import UsersPage from "./pages/UsersPage";
import AccountPage from "./pages/AccountPage";
import Page404 from "./pages/Page404";
import PageLoading from './pages/PageLoading';

// Custom Components
import AdminRoute from "./components/login/AdminRoute"
import PrivateRoute from "./components/login/PrivateRoute"
import AuthRoute from "./components/login/AuthRoute"

const COMPONENTS = {
    'SettingsPage': SettingsPage,
    'UsersPage': UsersPage,
    'HomePage': HomePage,
    'DatabasePage': DatabasePage,
    'PaymentPage': PaymentPage
}

const Routes = () => {

    const [routes, setRoutes] = useState({});
    const { isAuthenticated } = useAuth0();
    const { userProfile, loading } = useContext(SocketContext)

    // Set Routes
    useEffect(() => {
        if (isAuthenticated && !loading) {
            let tenants = [];
            let routes = {};
            if (userProfile.hasOwnProperty("tenants")) {
                tenants = Object.keys(userProfile.tenants)
            }

            tenants.forEach(t => {
                routes[`${t}_settings`] = {
                    path: `/${t}/settings`,
                    component: "SettingsPage",
                    admin: true
                }
                
                routes[`${t}_payment`] = {
                    path: `/${t}/payment`,
                    component: "PaymentPage",
                    admin: true
                }
                
                routes[`${t}_users`] = {
                    path: `/${t}/users`,
                    component: "UsersPage",
                    admin: true
                }

                routes[`${t}_databases`] = {
                    path: `/${t}/databases`,
                    component: "DatabasePage",
                    private: true
                }
                
                routes[`${t}_home`] = {
                    path: `/${t}/`,
                    component: "HomePage"
                }

            })

            setRoutes(routes)
        }

    }, [setRoutes, userProfile, loading, isAuthenticated])

    return (
        <Switch>
            {
                Object.keys(routes).map((key) => {
                    const route = routes[key];
                    const Component = COMPONENTS[route.component];

                    if (route.admin) {
                        return (
                            <AdminRoute
                                key={`route-${route.path}`}
                                path={route.path}
                                exact>
                                <div>
                                    <Component />
                                </div>
                            </AdminRoute>
                        )
                    }
                    if (route.private) {
                        return (
                            <PrivateRoute
                                key={`route-${route.path}`}
                                path={route.path}
                                exact>
                                <div>
                                    <Component />
                                </div>
                            </PrivateRoute>
                        )
                    }
                    return (
                        <Route
                            key={`route-${route.path}`}
                            path={route.path}
                            exact>
                            <div>
                                <Component />
                            </div>
                        </Route>
                    )
                })
            }

            {/* DEFAULT ROUTES */}
            <AuthRoute path="/account">
                <AccountPage />
            </AuthRoute>
            <Route exact path="/">
                <HomePage />
            </Route>
            <PrivateRoute path="*">
                { loading ? <PageLoading /> : <Page404 /> }
            </PrivateRoute>
            
        </Switch>
    );
}

Routes.defaultProps = {
    routes: []
}

export default Routes;