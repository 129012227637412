import React from "react";
import PropTypes from "prop-types";

// custom components
import Checkbox from "../common/checkbox/Checkbox"

class RoleBoxes extends React.Component {

    render() {
        let {allRoles, selectedRoles} = this.props;

        return (
            <div>
                {
                    allRoles.map((r, i) => {
                        let checked = r in selectedRoles && selectedRoles[r];
                        return <Checkbox key={r} checked={checked} title={r} onClick={this.props.selectRole.bind(this,r)} />
                    })
                }
            </div>
        );
    }
}

export default RoleBoxes;

RoleBoxes.propTypes = {
    allRoles: PropTypes.array.isRequired,
    selectedRoles: PropTypes.object.isRequired,
    selectRole: PropTypes.func.isRequired,
};
