import React from "react";

// custom components
import Modal from '../common/modals/Modal'
import Input from '../common/form/Input'

const GridDownloadModal = (props) => {
 
    let {filename, table} = props;

    return (
        <Modal cancel={props.cancel} 
            title={`Download data from: ${table}`}
            enablePrimary={true}
            primaryTitle="Download" 
            primaryOnClick={ () => {props.download()} }
        >
            <div className="w-2/3">
                <div className="flex flex-row">
                    <Input 
                        outerClass={"flex-grow"}
                        placeHolder={"Enter file name"}
                        onChange={(e) => {props.changeName(e.target.value)}}
                        inputValue={filename}
                        className={""} 
                        autoFocus
                    />
                    <div className="mt-4">.csv</div>
                </div>
                
            </div>
        </Modal>
    );
}

export default GridDownloadModal;