import React from "react";
import PropTypes from "prop-types";

// custom components
import ButtonDanger from "../common/button/ButtonDanger"
import ButtonWarning from "../common/button/ButtonWarning"
import Modal from "../common/modals/Modal"
import SettingsContainer from "../common/settings/SettingsContainer"


class SettingModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            confirm: false
          }
    }

    confirm = () => {
        this.setState({
            confirm: !this.state.confirm
        })
    }

    render() {

        return (
            <Modal title="Manage user" cancel={this.props.onCancel}>
                {/* <SettingsContainer
                    setting="Suspend User"
                    settingDesc={"This operation will inactivate the user.  They will no longer be able to log in."}
                >
                    <ButtonWarning title="Deactivate User" />
                </SettingsContainer> */}
                
                {
                    this.props.user.status === "Active"
                    ? <SettingsContainer
                        setting={`${this.props.user.hasOwnProperty("tenants")  
                                    && this.props.user.tenants.hasOwnProperty(this.props.tenant)
                                    && this.props.user.tenants[this.props.tenant] === "admin" ? "Remove" : "Make"} Admin`}
                        settingDesc={`Toggle making this user an admin for your instance. This gives them the ability to manage the connection, invite users.`}
                    >
                        {
                            this.props.user.hasOwnProperty("tenants")  
                                    && this.props.user.tenants.hasOwnProperty(this.props.tenant)
                                    && this.props.user.tenants[this.props.tenant] === "admin"
                            ?  this.state.confirm 
                                ? <ButtonDanger className="text-red-700 font-bold" title="Click again to confirm" onClick={() => {this.props.flipAdmin()}} />
                                : <ButtonDanger title="Remove Admin" onClick={() => {this.confirm()}} />
                            : this.state.confirm
                                ? <ButtonWarning className="text-yellow-700 font-bold" title="Click again to confirm" onClick={() => {this.props.flipAdmin()}} />
                                : <ButtonWarning title="Make Admin" onClick={() => {this.confirm()}} />
                        }
                    </SettingsContainer>
                    : null
                }
                <SettingsContainer
                    setting="Delete User"
                    settingDesc={"This operation is permanent and will remove this user.  Note, you cannot delete an admin, please remove them as an admin first and then delete."}
                >
                    <ButtonDanger 
                        title="Remove User" 
                        onClick={() => {this.props.deleteUser()}} 
                        disabled={
                            this.props.user.hasOwnProperty("tenants")  
                                && this.props.user.tenants.hasOwnProperty(this.props.tenant)
                                && this.props.user.tenants[this.props.tenant] === "admin"
                            ? true 
                            : null
                        }
                    />
                </SettingsContainer>
                
            </Modal>
        );
    }
}

export default SettingModal;

SettingModal.propTypes = {
    user: PropTypes.object.isRequired,
    tenant: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    flipAdmin: PropTypes.func.isRequired,
};
