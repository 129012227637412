import React, {useContext} from "react";
// import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// contexts
import { SocketContext } from "../contexts/socketContext";

// Images
import WinterfaceFull from "../images/WinterfaceFull"

// Custom Components
import LoginButton from "../components/login/LoginButton";
import Onboarding from "../components/login/Onboarding";
import ButtonPrimary from "../components/common/button/ButtonPrimary"
import TenantList from "../components/tenant/TenantList"


function HomePage() {

    let history = useHistory();
    let location = useLocation();
    const { isAuthenticated, user } = useAuth0();
    const { userProfile, checkUniqueInstance, submitOnboarding, testConnection, resendVerification, updCurrentTenant } = useContext(SocketContext)

    let tenants = userProfile.tenants || {}
    let currentTenant = userProfile.currentTenant || ""

    // redirect once onboarding complete, do not redirect if redirected because not admin
    let { from } = location.state || { from: { pathname: "/" } };
    let { adminFlag } = location.state || { adminFlag: false };
    if ( from.pathname !== "/" 
            && userProfile.hasOwnProperty("onboarding_status") 
            && userProfile.onboarding_status === "complete"
            && !adminFlag
    ) {
        history.replace(from)
    }


    // construct authProfile
    let authProfile = {}
    if (user) {       
        if (user.hasOwnProperty("email")) { 
            authProfile.email = user.email
            let parsedEmail = user.email.split("@")
            let parsedDomain = parsedEmail[1].split(".")
            authProfile.hintInstance = parsedDomain[0]
        }
        if (user.hasOwnProperty("given_name")) { authProfile.firstname = user.given_name }
        if (user.hasOwnProperty("family_name")) { authProfile.lastname = user.family_name }
        if (user.hasOwnProperty("email")) { authProfile.email = user.email.toLowerCase() }
    }
    

    return (
        <div className="pl-20 py-20 h-screen w-full">
                <div className="mx-auto max-w-md">
                    <WinterfaceFull className="fill-current dark:text-white" />
                </div>

                {
                    isAuthenticated
                    ?   <div>Logged in as {user.email}</div>
                    :   <div className="mx-auto w-36 my-8">
                            <LoginButton />
                        </div>
                }
                    
                {
                    Object.keys(tenants).length === 0 
                    ? null  
                    : <TenantList tenants={tenants} currentTenant={currentTenant} updCurrentTenant={updCurrentTenant} />
                }

                {
                    userProfile.hasOwnProperty("onboarding_status") && userProfile.onboarding_status === "unverified"
                    ?   <div className="py-8">
                            <div>Please check your email for a verification link to continue.</div>
                            <div>If you've verified your email already, close this tab and try to log in again.</div>
                            <div className="mx-auto w-48 pt-8">
                                <ButtonPrimary title="Resend Verification" onClick={resendVerification} />
                            </div>
                        </div>
                    :   (
                            userProfile.hasOwnProperty("onboarding_status") 
                            && userProfile.onboarding_status !== "complete"
                        ) || (
                            !userProfile.hasOwnProperty("onboarding_status") 
                            && Object.keys(userProfile).length > 0
                        )
                        ?   <Onboarding 
                                user={authProfile} 
                                checkUnique={checkUniqueInstance}
                                submitOnboarding={submitOnboarding}
                                testConnection={testConnection}
                            />
                        : null
                }


        </div>
    );
}

export default HomePage;
