import React from "react";
import PropTypes from "prop-types";

// custom components
import Modal from "../common/modals/Modal";
import Button from "../common/button/Button";
import ButtonWarning from "../common/button/ButtonWarning";
import ButtonPrimary from "../common/button/ButtonPrimary";

// icons
import { Check } from 'react-feather';


class PlanConfiguration extends React.Component {

    constructor(props) {
        super(props);

        let {config} = this.props;
        
        if (config.tier === "Free") {
            config.seats = 1
            config.monthly = false
        }

        let newConfig = JSON.parse(JSON.stringify(config));

        let checkoutReady = false;

        this.state = {
            newConfig,
            checkoutReady,
            errorText: "",
            status: "Click to test"
          }
    }


    change = (source, value) => {
        let {newConfig, checkoutReady} = this.state;

        if (source === "tier" && value === "Free") {
            newConfig.seats = 1
            newConfig.monthly = false
        }

        if (source === "seats") {
            value = parseInt(value)
        }

        newConfig[source] = value;

        if (source === "tier") {
            if (parseInt(newConfig.currentUsers) > newConfig.seats) {
                this.setState({
                    newConfig,
                    errorText: "Please remove additional users before downgrading the # of seats."
                })
            } else {
                this.setState({
                    newConfig,
                    checkoutReady: !checkoutReady,
                    errorText: ""
                })
            }
        } else {
            this.setState({
                newConfig
            })
        }
        
        
    }

    select = () => {
        let {newConfig} = this.state;

        if (parseInt(newConfig.currentUsers) > newConfig.seats) {
            this.setState({
                errorText: "Please remove additional users before downgrading the # of seats."
            })
        } else {
            this.setState({
                checkoutReady: !this.state.checkoutReady,
                errorText: ""
            })
        }
        
    }

    submit = () => {
        let {newConfig} = this.state;
        let {config} = this.props;

        // ensure seats = a minimum of 2
        if (newConfig.tier === "Team" && newConfig.seats < 2) {
            newConfig.seats = 2
        }
        
        this.props.submit(newConfig, config.tier, this.checkoutCB)

    }

    checkoutCB = (url) => {
        window.location.replace(url);
    }

    render() {
        let {config} = this.props;
        let {newConfig, checkoutReady, errorText} = this.state;

        let seatText = newConfig.seats === 1 ? 2 : newConfig.seats;
        
        return (
            <Modal title="Configure Plan" 
                cancel={this.props.cancel}
                enablePrimary={checkoutReady}
                primaryTitle="Checkout"
                primaryOnClick={() => {this.submit()}}
            >
                {
                    newConfig.monthly
                    ? <div className="flex justify-center items-center">
                            <button className="bg-gray-300 dark:bg-gray-800 hover:bg-white hover:text-white font-semibold font-bold py-2 px-2 rounded-l-lg"
                                onClick={() => this.change("monthly", true)}
                            >
                                Monthly
                            </button>
                            <button className="bg-gray-200 dark:bg-gray-600 hover:bg-white hover:text-white font-semibold font-bold py-2 px-2 rounded-r-lg"
                                onClick={() => this.change("monthly", false)}
                            >
                                Annual
                            </button>
                        </div>
                    : <div className="flex justify-center items-center">
                            <button className="bg-gray-200 dark:bg-gray-600 hover:bg-white hover:text-white font-semibold font-bold py-2 px-2 rounded-l-lg"
                                onClick={() => this.change("monthly", true)}
                            >
                                Monthly
                            </button>
                            <button className="bg-gray-300 dark:bg-gray-800 hover:bg-white hover:text-white font-semibold font-bold py-2 px-2 rounded-r-lg"
                                onClick={() => this.change("monthly", false)}
                            >
                                Annual
                            </button>
                        </div>
                }
                

                <div className="flex flex-col lg:flex-row w-full">
                    <div className="rounded-lg mx-2 my-2 lg:my-8 p-4 pb-8 pt-2 shadow-lg bg-gray-100 dark:bg-gray-800 w-full ">
                        <div className="w-full text">Single user</div>
                        <div className="w-full font-bold text-4xl">Free</div>
                        <div className="h-1/2 divide-gray-300">
                            <div>Get started for free</div>
                        </div>
                        {
                            checkoutReady && newConfig.tier === "Free"
                                ?   <div className="flex justify-center items-center pt-2">
                                        <button
                                            title={"Selected"}
                                            className="w-8 h-8 focus:outline-none rounded-full bg-green-400 text-white"
                                            onClick={() => {this.select()}}
                                        >
                                            <Check className="h-5 w-5 mx-auto" /> 
                                        </button>
                                    </div>
                                : config.tier === "Free"
                                    ? <Button title="Current" disabled />
                                    : <ButtonWarning title="Downgrade" onClick={() => {this.change("tier", "Free")}} />
                        }
                    </div>
                    <div className="rounded-lg mx-2 my-2 lg:my-8 p-4 pb-8 pt-2 shadow-lg bg-gray-100 dark:bg-gray-800 w-full ">
                        <div className="w-full text">{"Up to 10 seats"}</div>
                        <div className="w-full font-bold text-4xl">Team</div>
                        <div className="h-1/2 divide-gray-300">
                            <div className="py-2">{newConfig.monthly ? "$100 / seat / month" : "$50 / seat / month"}</div>
                            <div className="py-2">
                                <input 
                                    id="numSeatsRange"
                                    type="range" 
                                    min="2" 
                                    max="10" 
                                    value={newConfig.seats} 
                                    onChange={(e) => this.change("seats", e.target.value)} 
                                    disabled={checkoutReady ? true : undefined}
                                />
                                <div className="w-full">
                                    {
                                        newConfig.monthly
                                            ? `${seatText} seats - $${seatText * 100} / mo`
                                            : `${seatText} seats - $${seatText * 600} / yr`
                                    }
                                </div>
                            </div>
                            
                        </div>
                        {
                            checkoutReady && newConfig.tier === "Team"
                                ? <div className="flex justify-center items-center pt-2">
                                        <button
                                            title={"Selected"}
                                            className="w-8 h-8 focus:outline-none rounded-full bg-green-400 text-white"
                                            onClick={() => {this.select()}}
                                        >
                                            <Check className="h-5 w-5 mx-auto" /> 
                                        </button>
                                    </div>
                                : config.tier === "Team" && config.seats === newConfig.seats && config.monthly === newConfig.monthly
                                    ? <Button title="Current" disabled={true} />
                                    : config.tier === "Free"
                                        ? <ButtonPrimary title="Upgrade" onClick={() => {this.change("tier", "Team")}} />
                                        : (newConfig.seats >= config.seats && !newConfig.monthly && config.monthly) 
                                                || (newConfig.seats > config.seats)
                                            ? <ButtonPrimary title="Upgrade" onClick={() => {this.change("tier", "Team")}} />
                                            : <ButtonWarning title="Downgrade" onClick={() => {this.change("tier", "Team")}} />
                        }
                                

                    </div>
                    <div className=" rounded-lg mx-2 my-2 lg:my-8 p-4 pb-8 pt-2 shadow-lg bg-gray-100 dark:bg-gray-800 w-full ">
                        <div className="w-full text">11+ seats</div>
                        <div className="w-full font-bold text-4xl">Enterprise</div>
                        <div className="h-1/2 divide-gray-300">
                            <div>Contact us to scale up</div>
                        </div>
                        <ButtonPrimary title="Contact Us" onClick={(e) => {window.location.href ='support@winterface.io';}} />
                    </div>
                </div>

                <div className="mt-4 p-1 w-full font-bold">
                    {
                        errorText === ""
                        ? null
                        : <div className="pb-2 text-red-500">{errorText}</div>
                    }
                </div>
                    
            </Modal>
        );
    }
}

export default PlanConfiguration;

PlanConfiguration.propTypes = {
    config: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
};
