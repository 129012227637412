import React from "react";
import PropTypes from "prop-types";

// Custom Components
import DatabaseItem from "./DatabaseItem"
import SchemaAdd from "./SchemaAdd"
import TableItem from "./TableItem"
import TableItemAdd from "./TableItemAdd"
import AddTable from "../addTable/AddTable"

class DatabaseList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expandedDatabases: this.getInitialDB(),
            addTableOpen: false,
            selectedSchema: "",
        }
    }
    
    getInitialDB = () => {
        let expDBs = {}
        if (Object.keys(this.props.selectedTable).length > 0) {
            if (this.props.selectedTable.hasOwnProperty("Schema")) {
                expDBs[this.props.selectedTable.Schema] = true
            }            
        }

        return expDBs
    }

    isDBExpanded = (db) => {
        if ( this.state.expandedDatabases.hasOwnProperty(db) )  {
            if ( this.state.expandedDatabases[db] === true ) {
                return true;
            }
        }
        return false;
    }
    
    toggleDBExpand = (db) => {
        let {expandedDatabases} = this.state;
        if ( expandedDatabases.hasOwnProperty(db) )  {
            expandedDatabases[db] = !expandedDatabases[db]
        } else {
            expandedDatabases[db] = true
        }
        
        this.setState({
            expandedDatabases: expandedDatabases,
        });
    }

    toggleModal = (schema) => {
        this.setState({
            addTableOpen: !this.state.addTableOpen,
            selectedSchema: schema || "",
        })
    }
    
    addTable = (schema) => {
        this.toggleModal(schema);
    }

    render() {

        return (
            <div>
                {
                    this.state.addTableOpen 
                        ?   <AddTable schemas={this.props.dbData} 
                                    sourceSchema={this.state.selectedSchema} 
                                    addTable={this.props.addTable}
                                    toggleModal={this.toggleModal} 
                                    inModal={true}
                            />
                        : null
                }
                
                <ul className="text-wdarkgrey dark:text-wgray text-left text-sm sm:text-base">
                    {
                        this.props.dbData.map((db, index) => 
                            <DatabaseItem key={db.schema + "-" + index}
                                data-testid={db.schema}
                                schema={db.schema}
                                expand={this.toggleDBExpand}
                                expanded={this.isDBExpanded(db.schema)}
                            >
                                {
                                    db.tables.map((table, i) => 
                                        <TableItem key={table.Table + "-" + i}
                                            data-testid={table.Table}
                                            schema={db.schema}
                                            table={table.Table}
                                            setTable={this.props.setTable} 
                                            active={this.props.selectedTable.SchemaTable === (table.SchemaTable)}
                                            tableObject={table}
                                            tenant={this.props.tenant}
                                        />
                                    )
                                }
                                {
                                    this.props.inNavBar 
                                        ? <TableItemAdd schema={db.schema} addTable={this.addTable} /> 
                                        : db.tables.length > 0
                                            ? null 
                                            : <div className="pl-8 py-1 mb-1">No Tables</div>
                                }
                                
                            </DatabaseItem>
                        )
                    }
                    <SchemaAdd addSchema={this.props.addSchema}/>
                </ul>
            </div>
        );
    }
}

export default DatabaseList;

DatabaseList.propTypes = {
    tenant: PropTypes.string.isRequired,
    selectedTable: PropTypes.object.isRequired,
    inNavBar: PropTypes.bool.isRequired,
    dbData: PropTypes.array.isRequired,
    setTable: PropTypes.func.isRequired,    
    addTable: PropTypes.func.isRequired,
    addSchema: PropTypes.func.isRequired,
};
