import React from "react";
// import PropTypes from "prop-types";

// helpers
import {checkValidText, checkRequired} from "../../helpers/formValidation"

// custom components
import Button from "../common/button/Button";
import ButtonPrimary from "../common/button/ButtonPrimary";
import OnboardingContainer from "./OnboardingContainer.jsx"
import OnboardingOption from "./OnboardingOption.jsx"


class Onboarding extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onboardChanges: {
                ...props.user
            },
            validationErrors: {},
            connectionStatus: "Click to test"
        }
    }


    //////////////
    // Handlers //
    //////////////

    validateChange = (source, value) => {
        let {validationErrors} = this.state;

        // delete any existing error
        if (validationErrors.hasOwnProperty(source)) {
            delete validationErrors[source]
        }

        // INSTANCE
        if (source === "instance") {
            validationErrors = checkValidText(validationErrors, source, value)            
        }

        return validationErrors
    }

    onChangeHandler = (source, value) => {
        let {onboardChanges} = this.state
        onboardChanges[source] = value

        // check for errors 
        let validationErrors = this.validateChange(source,value)

        // set changes
        this.setState({
            onboardChanges,
            validationErrors
        })
    }

    isUnique = ({unique}) => {
        if (!unique) {
            let {validationErrors} = this.state;

            if (validationErrors.hasOwnProperty("instance")) {
                delete validationErrors["instance"]
            }
            
            validationErrors = {
                ...validationErrors,
                instance: "This name is already taken"
            }
            this.setState({
                validationErrors
            })
        }
    }

    onBlurHandler = (source, value) => {
        let {onboardChanges, validationErrors} = this.state;

        // set to lowercase
        if (source === "instance") {
            value = value.toLowerCase()
            onboardChanges[source] = value
        }

        // delete any existing error
        if (validationErrors.hasOwnProperty(source)) {
            delete validationErrors[source]
        }

        // ANY REQUIRED
        validationErrors = checkRequired(validationErrors, source, value)

        // UNIQUE INSTANCE
        if (source === "instance") {
            this.props.checkUnique(value, this.isUnique)
        }

        // set changes
        this.setState({
            onboardChanges,
            validationErrors
        })
    }

    fulfillTestConnection = (d) => {
        let {validationErrors} = this.state

        if (d.status === "Success") {
            if (validationErrors.hasOwnProperty("connection")) {
                delete validationErrors["connection"]
            }

            this.setState({
                connectionStatus: "Success",
                validationErrors
            })
        } else {
            validationErrors["connection"] = d.error

            this.setState({
                connectionStatus: "Error - Test Again",
                validationErrors
            })
        }
    }

    testConnection = () => {
        let {onboardChanges} = this.state;
        let data = {
            "UserName": onboardChanges.sfuser,
            "Password": onboardChanges.password,
            "Account": onboardChanges.sfinstance + ".snowflakecomputing.com",
            "Database": onboardChanges.sfdb,
            "Role": onboardChanges.sfrole,
            "Warehouse": onboardChanges.sfwarehouse,
            testExisting: false,
        }
        this.props.testConnection(data, this.fulfillTestConnection)
    }

    
    submit = () => {
        let {onboardChanges, validationErrors} = this.state;

        validationErrors = checkRequired(validationErrors, "instance", onboardChanges.instance || "")
        validationErrors = checkRequired(validationErrors, "firstname", onboardChanges.firstname || "")
        validationErrors = checkRequired(validationErrors, "lastname", onboardChanges.lastname || "")
        validationErrors = checkRequired(validationErrors, "email", onboardChanges.email || "")
        validationErrors = checkRequired(validationErrors, "sfinstance", onboardChanges.sfinstance || "")
        validationErrors = checkRequired(validationErrors, "sfrole", onboardChanges.sfrole || "")
        validationErrors = checkRequired(validationErrors, "sfuser", onboardChanges.sfuser || "")
        validationErrors = checkRequired(validationErrors, "password", onboardChanges.password || "")
        validationErrors = checkRequired(validationErrors, "sfwarehouse", onboardChanges.sfwarehouse || "")
        validationErrors = checkRequired(validationErrors, "sfdb", onboardChanges.sfdb || "")
        
        if (this.state.connectionStatus === "Click to test") {
            validationErrors.connection = "Test connection before continuing"
        }

        if (Object.keys(validationErrors).length > 0) {
            this.setState({
                validationErrors
            })
        } else {
            this.props.submitOnboarding(onboardChanges, () => {})
        }

    }


    render() {

        return (
            <div className="w-full sm:pl-20">
                <div className="mx-auto text-left md:max-w-7xl xl:max-w-5xl h-screen-64 overflow-y-auto">
                    <OnboardingContainer 
                        title="Instance name"
                        description="This will be used to distinguish your Winterface account in generated links when inviting new teammates. The instance name should be something easy to remember and contain only letters, numbers, or underscores (no spaces)."
                    >
                        <OnboardingOption 
                            title="Instance"
                            placeholder={"e.g. " + (this.state.onboardChanges.hintInstance || "company_name")}
                            error={
                                this.state.validationErrors.hasOwnProperty("instance") 
                                ? this.state.validationErrors.instance 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.instance || ""}
                            onChange={(e) => this.onChangeHandler("instance", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("instance", e.target.value)}
                        />
                    </OnboardingContainer>
                    
                    
                    <OnboardingContainer 
                        title="User information"
                        description="This will be used to display your information within Winterface."
                    >
                        <OnboardingOption 
                            title="First Name"
                            placeholder=""
                            error={
                                this.state.validationErrors.hasOwnProperty("firstname") 
                                ? this.state.validationErrors.firstname 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.firstname || ""}
                            onChange={(e) => this.onChangeHandler("firstname", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("firstname", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Last Name"
                            placeholder=""
                            error={
                                this.state.validationErrors.hasOwnProperty("lastname") 
                                ? this.state.validationErrors.lastname 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.lastname || ""}
                            onChange={(e) => this.onChangeHandler("lastname", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("lastname", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Email"
                            placeholder="me@mycompany.com"
                            error={
                                this.state.validationErrors.hasOwnProperty("email") 
                                ? this.state.validationErrors.email 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.email || ""}
                            onChange={(e) => this.onChangeHandler("email", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("email", e.target.value)}
                            disabled={true}
                        />
                    </OnboardingContainer>

                    
                    <OnboardingContainer 
                        title="Snowflake connection"
                        description="Link Winterface to your Snowflake instance, see: "
                        doclink="https://winterface.io/docs/getting-started"
                        doclinkText="Getting Started Documentation"
                    >
                        
                        <div className="flex flex-col sm:flex-row">
                            <OnboardingOption 
                                title="Snowflake Account"
                                placeholder="myinstance.us-east-1"
                                outerClass="w-full"
                                error={
                                    this.state.validationErrors.hasOwnProperty("sfinstance") 
                                    ? this.state.validationErrors.sfinstance 
                                    : undefined
                                }
                                inputValue={this.state.onboardChanges.sfinstance || ""}
                                onChange={(e) => this.onChangeHandler("sfinstance", e.target.value)}
                                onBlur={(e) => this.onBlurHandler("sfinstance", e.target.value)}
                            />
                            <div className="mb-2 sm:mt-11 px-2 w-32">.snowflakecomputing.com</div>
                        </div>
                        <OnboardingOption 
                            title="Snowflake Role"
                            placeholder="WINTERFACE_ROLE"
                            error={
                                this.state.validationErrors.hasOwnProperty("sfrole") 
                                ? this.state.validationErrors.sfrole 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.sfrole || ""}
                            onChange={(e) => this.onChangeHandler("sfrole", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("sfrole", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Snowflake User"
                            placeholder="WINTERFACE_USER"
                            error={
                                this.state.validationErrors.hasOwnProperty("sfuser") 
                                ? this.state.validationErrors.sfuser 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.sfuser || ""}
                            onChange={(e) => this.onChangeHandler("sfuser", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("sfuser", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Snowflake Password"
                            type="password" 
                            placeholder=""
                            error={
                                this.state.validationErrors.hasOwnProperty("password") 
                                ? this.state.validationErrors.password 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.password || ""}
                            onChange={(e) => this.onChangeHandler("password", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("password", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Snowflake Warehouse"
                            placeholder="WINTERFACE_WH"
                            error={
                                this.state.validationErrors.hasOwnProperty("sfwarehouse") 
                                ? this.state.validationErrors.sfwarehouse 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.sfwarehouse || ""}
                            onChange={(e) => this.onChangeHandler("sfwarehouse", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("sfwarehouse", e.target.value)}
                        />
                        <OnboardingOption 
                            title="Default Database"
                            placeholder="WINTERFACE_DB"
                            error={
                                this.state.validationErrors.hasOwnProperty("sfdb") 
                                ? this.state.validationErrors.sfdb 
                                : undefined
                            }
                            inputValue={this.state.onboardChanges.sfdb || ""}
                            onChange={(e) => this.onChangeHandler("sfdb", e.target.value)}
                            onBlur={(e) => this.onBlurHandler("sfdb", e.target.value)}
                        />
                        <div className="flex flex-row">
                            <div className="p-1 w-64">
                                <Button onClick={this.testConnection} title={this.state.connectionStatus} />
                                {
                                    this.state.validationErrors.hasOwnProperty("connection") 
                                        ?   <span className="text-red-500 text-sm">
                                                {this.state.validationErrors.connection}
                                            </span>
                                        :   null
                                }

                            </div>
                        </div>
                    </OnboardingContainer>
                    <OnboardingContainer>
                        
                        <div className="sm:w-1/2 sm:mx-auto">
                            {
                                Object.keys(this.state.validationErrors).length > 0
                                ? <div className="pb-2 text-red-500 text-sm">Please correct any errors above, then click Get Started.</div>
                                : null
                            }
                            <ButtonPrimary onClick={this.submit} title="Get Started" />
                        </div>
                    </OnboardingContainer>
                </div>
            </div>
        );
    }
}

export default Onboarding;

// Onboarding.propTypes = {
//     sourceSchema: PropTypes.string,
// };