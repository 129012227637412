import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Context
import {PageContext} from "../../contexts/pageContext";
import {SocketContext} from "../../contexts/socketContext";

// Custom Components
import StandardLink from "./StandardLink"
import Avatar from "../common/Avatar"

// Images & Icons
import winlogo from "../../images/winterface_logo.svg";
import { ChevronsLeft, Menu, Home, Settings, Users, Database, LogIn } from "react-feather";


const OuterNavBar = () => {


        const { userProfile } = useContext(SocketContext)
        const { data, toggleMenu, setMenuNeeded, setMenuNotNeeded } = useContext(PageContext);
        const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

        let alreadySelectedTable = (Object.keys(data.selectedTable).length > 0)

        let currentPath = useLocation().pathname;
        let currentTenant = userProfile.currentTenant || ""
        let dbFunction = (currentPath === `/${currentTenant}/databases`) ? toggleMenu : setMenuNeeded
        
        let isAdmin = userProfile.hasOwnProperty("tenants") 
            && userProfile.hasOwnProperty("currentTenant") 
            && userProfile.tenants.hasOwnProperty(userProfile.currentTenant) 
            && userProfile.tenants[userProfile.currentTenant] === "admin";
        
        return (
            <div className="fixed bg-gray-800 h-screen flex flex-col justify-between">
                <div className="overflow-auto">
                    <div className="h-16 bg-gray-600 text-white flex items-center justify-center">
                        <Link to={currentTenant === "" ? "/" : `/${currentTenant}/`}
                            title="Home"
                            className="block px-5 text-white text-lg h-8 flex items-center justify-center"
                            data-testid="logoHome"
                            onClick={(e) => setMenuNotNeeded(e)}
                        >
                            <img
                                className="h-10 w-10"
                                src={winlogo}
                                alt="Winterface.io"
                            />
                        </Link>
                        
                    </div>
                    {
                        isAuthenticated 
                            && userProfile.hasOwnProperty("onboarding_status") 
                            && userProfile.onboarding_status === "complete"
                        ?   <ul className="text-white items-center text-center">
                                {
                                    data.menuNeeded 
                                    ? <li >
                                        <button
                                            title="Toggle Menu"
                                            className="block p-3 w-full bg-gray-700 border-b border-gray-800 items-center hover:text-wblue focus:outline-none"
                                            onClick={toggleMenu}
                                        >
                                            {
                                                data.innerOpen 
                                                    ? <ChevronsLeft data-testid="menuOpenIcon" className={"mx-auto"} />
                                                    : <Menu data-testid="menuClosedIcon" className={"mx-auto"} />                                       
                                            }
                                        </button>
                                    </li>
                                    : null
                                }
                                    
                                    <StandardLink to={"/"} title="Home" setMenu={setMenuNotNeeded} >
                                        <Home className={"mx-auto"} data-testid="homeIcon" />
                                    </StandardLink>
            
                                    <StandardLink 
                                        to={alreadySelectedTable 
                                                ? `/${currentTenant}/databases?table=${data.selectedTable.SchemaTable}` 
                                                : `/${currentTenant}/databases`
                                            } 
                                        title="Databases" 
                                        setMenu={dbFunction} 
                                    >
                                        <Database className={"mx-auto"} />
                                    </StandardLink>

                                    { 
                                    isAdmin
                                    ? <StandardLink to={`/${currentTenant}/users`} title="Users" setMenu={setMenuNotNeeded} >
                                        <Users className={"mx-auto"} />
                                    </StandardLink> 
                                    : null 
                                    }
                                    
                                    { 
                                    isAdmin
                                    ? <StandardLink to={`/${currentTenant}/settings`} title="Settings" setMenu={setMenuNotNeeded} >
                                        <Settings className={"mx-auto"} />
                                    </StandardLink>
                                    : null 
                                    }
                                
                            </ul>
                        : null
                    }
                    
                </div>


                {
                    isAuthenticated && !isLoading
                    ?   <Link to="/account"
                            title="Account"
                            className="block p-4 bg-gray-900 "
                            onClick={(e) => setMenuNotNeeded(e)}
                        >
                            <Avatar alt={user.nickname.substring(0, 2).toUpperCase()} image={user.picture} />
                        </Link>
                    :   <div className="block p-4 bg-gray-900">
                            <button className="p-1 h-10 w-10 rounded-full object-cover mx-auto text-center text-xl text-white font-medium bg-gray-600 hover:text-wblue hover:bg-gray-700"
                                onClick={() => {loginWithRedirect()}}
                                data-testid={"loginButton"}
                            >
                                <LogIn className="h-7 w-7" />
                            </button>
                        </div>
                }
            </div>
        );
};

export default OuterNavBar;
