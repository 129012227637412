import React from "react";

// custom components
import SettingsListItem from "../common/settingsList/SettingsListItem"

// Images & Icons
import { ToggleRight, ToggleLeft } from "react-feather";

function TenantItem(props) {
    
    // constructor(props) {
    //     super(props);
    // }

    
    return (
        <SettingsListItem>
            <div className="p-4 w-0 invisible sm:w-1/12 sm:visible"></div>
            <div className="flex-grow py-5">
                <div className="font-semibold">{props.tenantName} {props.currentTenantFlag ? <span className="italic text-sm font-normal">(current)</span> : null}</div>
            </div>
            {
                props.currentTenantFlag 
                ? <div className="py-5 px-2 md:px-10">
                        <button className="focus:outline-none text-wblue" 
                                data-testid="toggleButton"
                                onClick={() => {}}
                        >
                            <ToggleRight /> 
                        </button>
                    </div>
                : <div className="py-5 px-2 md:px-10">
                        <button className="focus:outline-none hover:text-wblue" 
                                data-testid="toggleButton"
                                onClick={() => {
                                    props.setTenant(props.tenantName)
                                }}
                        >
                            <ToggleLeft />
                        </button>
                    </div>
            }
        </SettingsListItem>
    );
}

export default TenantItem;

// TenantItem.propTypes = {
//     tenantName: PropTypes.string.isRequired,
//     tenantRole: PropTypes.string.isRequired,
//     currentTenantFlag: PropTypes.bool.isRequired,
//     setTenant: PropTypes.func.isRequired,
// };
