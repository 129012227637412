import React, { useContext, useState, useEffect, useCallback } from "react";

// contexts
import { SocketContext } from "../contexts/socketContext";

// Custom Components
import AddUserModal from "../components/user/AddUserModal"
import Container from "../components/common/Container"
import Card from "../components/common/Card"
import SettingsListContainer from "../components/common/settingsList/SettingsListContainer"
import UserItem from "../components/user/UserItem"
import RoleModal from "../components/user/RoleModal"
import SettingModal from "../components/user/SettingModal"

function UsersPage() {

    // contexts
    const { socket } = useContext(SocketContext)

    // internal state
    const [state, setState] = useState({
        openRoleModal: false, 
        openSettingModal: false, 
        openUserModal: false,
        errorText: "",
        roles:[], 
        selectedUser:{}
    });
    const [users, setUsers] = useState([])
    const [tenant, setTenant] = useState("")
    const [seats, setSeats] = useState(0)

    
    //////////////////////////////
    // initial request for data //
    //////////////////////////////

    // fulfillUsers is a callback tied to "reqUsers" to process response
    const fulfillUsers = useCallback((d) => {
        setUsers(d.users);
        setTenant(d.tenant);
        setSeats(d.seats);
    }, [setUsers, setTenant, setSeats]);

    // initial request for data when coming on to page
    useEffect(() => {

        // only get data if a table is set and hasn't been run before
        if (users.length === 0) {
            socket.emit("reqUsers", { },
                (response) => { fulfillUsers(response) }
            )
        }

    }, [socket, users, fulfillUsers]);

    //////////////////////
    // general emitters //
    //////////////////////

    const saveRoles = (roles) => {

        socket.emit("updRoles"
            , { wio__wuser_id: state.selectedUser.wio__wuser_id, roles }
            , (response) => { fulfillUsers(response) }
        )
        toggleRoleModal()
    }
    
    const deleteUser = () => {

        socket.emit("deleteUser"
            , { wio__wuser_id: state.selectedUser.wio__wuser_id }
            , (response) => { fulfillUsers(response) }
        )

        toggleSettingModal()
    }
    
    const flipAdmin = () => {

        socket.emit("updAdmin"
            , { 
                wio__wuser_id: state.selectedUser.wio__wuser_id,
                isAdmin: state.selectedUser.role === "admin"
            }
            , (response) => { fulfillUsers(response) }
        )

        toggleSettingModal()
    }
    
    //////////////////////////
    // component & handlers //
    //////////////////////////

    function toggleRoleModal() {
        setState({...state, openRoleModal: !state.openRoleModal, errorText:""});
    }
    
    function openRoleModal(userID) {
        let i = users.findIndex(u => u.wio__wuser_id === userID)
        let u = users[i]
        setState({...state, openRoleModal: true, roles: u.roles, selectedUser: u, errorText:""}); 
    }
    
    function toggleSettingModal() {
        setState({...state, openSettingModal: !state.openSettingModal, errorText:""});
    }
    
    function openSettingModal(u) {
        setState({...state, openSettingModal: true, selectedUser: u, errorText:""}); 
    }
    
    function toggleUserModal() {
        if (users.length >= seats) {
            setState({...state, errorText: "Please increase number of seats before adding new users."}); 
        } else {
            setState({...state, openUserModal: !state.openUserModal, errorText:""}); 
        }        
    }
    
    function addUser(user, roles) {
        user.id = user.email 
        user.status = "Invited"
        user.initials = user.firstname[0] + user.lastname[0]

        // // set roles from selected
        // let roleArray = []
        // allRoles.forEach((r) => {
        //     if (roles.hasOwnProperty(r) && roles[r]) {
        //         roleArray.push(r)
        //     }
        // })
        // user.roles = roleArray 
        
        socket.emit("addUser", user,
            (response) => { fulfillUsers(response) }
        )

        toggleUserModal()
    }

    return (
        <div className="pl-20 h-screen w-full">
            <Container leader="Users and Roles">
                <Card title="Manage Users">
                    <SettingsListContainer leader="Users" buttonTitle="Add User" buttonClick={() => {toggleUserModal()}}>
                        {
                            users.map((u, _) => {
                                return <UserItem 
                                            key={u.wio__wuser_id} 
                                            user={u} 
                                            onRolesClick={() => {openRoleModal(u.wio__wuser_id)}} 
                                            onSettingClick={(u) => {openSettingModal(u)}}
                                        />
                            })
                        }
                        <div className="mt-4 p-1 w-full font-bold">
                            <div className="pb-2 text-red-500">{state.errorText}</div>
                        </div>

                    </SettingsListContainer>
                    
                    {
                        state.openRoleModal 
                            ? <RoleModal 
                                    onCancel={() => {toggleRoleModal()}} 
                                    roles={state.roles} 
                                    // allRoles={allRoles}
                                    save={saveRoles}
                                />
                            : null
                    }
                    {
                        state.openSettingModal 
                            ? <SettingModal 
                                onCancel={() => {toggleSettingModal()}} 
                                user={state.selectedUser} 
                                deleteUser={deleteUser}
                                flipAdmin={flipAdmin}
                                tenant={tenant}
                                />
                            : null
                    }
                    {
                        state.openUserModal 
                            ? <AddUserModal 
                                onCancel={() => {toggleUserModal()}} 
                                // allRoles={allRoles} 
                                addUser={addUser} 
                                />
                            : null
                    }
                    
                </Card>

            </Container>
        </div>
    );
}

export default UsersPage;