import React from "react";
// import PropTypes from "prop-types";

class SettingsListItem extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            
        <div className="flex flex-row border bg-gray-300 dark:bg-gray-600 dark:border-gray-700 w-full">
            {this.props.children}
        </div>
        );
    }
}

export default SettingsListItem;

// SettingsListItem.propTypes = {
    
// };
