import React, { useState } from "react";

// custom components
import AddTableUpload from './addTable/AddTableUpload'
import Dropdown from '../common/dropdown/Dropdown'
import Modal from '../common/modals/Modal'

// libraries
import DataGrid from 'react-data-grid';
import Papa from "papaparse"

// helpers
import { csvComplete, backfillRows } from "../../helpers/fileValidation"

const GridUploadModal = (props) => {
    
    // props
    let {columns, schema, table} = props;

    // state
    const [action, setAction] = useState("append");
    const [mergeColumn, setMergeColumn] = useState("-none-");
    const [files, setFiles] = useState([]);
    const [cols, setCols] = useState([])
    const [rows, setRows] = useState([])
    const [error, setError] = useState("")

    // upload
    const upload = () => {

        if (action === "merge" && mergeColumn === "-none-") {
            setError("Please choose a column to match and merge on")
            return
        }

        let def = {
            schema,
            table,
            action,
            mergeColumn,
            cols,
            rows,
        }
        
        props.upload(def);
    };

    // onDrop
    const onDrop = (fs, rejectedfiles, event) => {
        // handle rejections
        if (rejectedfiles.length > 0) {
            if (rejectedfiles[0].errors.length > 0) {
                setError(rejectedfiles[0].errors[0].code === "file-invalid-type"
                            ? "File must be a delimited text file (csv, tsv, etc)"
                            : rejectedfiles[0].errors[0].message)
            }
        } else {
            // handle accepts
            
            fs.forEach((f) => {
                Papa.parse(f, {
                    header: true,
                    complete: finishDrop,
                    error: () => {setError("Bad file, please try a different one")},
                    dynamicTyping: true,
                    skipEmptyLines: true
                });                           
            });

        }
    }

    const compareColumns = (origC, newC) => {
        let e = ""
        newC.forEach((nc, i) => {

            // Test if column name can be found
            let j = origC.findIndex(oc => oc.key === nc.key)
            if (j === -1) {
                e += `Could not match column ${nc.name} \n`
            }

            // Test if type is the same for new column
            // the test ignores mismatch if the original column is text
            if (j > -1 && origC[j].type !== "Text" && origC[j].type !== nc.type) {
                e += `'${origC[j].type}' type expected for column '${nc.name}', received: '${nc.type}' \n`
            }
        })

        return e
    }

    const finishDrop = async (r, f) => {
        
        let res = await csvComplete(r, f)

        // this fxn will be called by a second parse w/o dynamic typing
        // so that any detected text columns can retain preceding 0s
        const finalizeRows = async (r2, f2) => {
        
            let res2 = await backfillRows(r2, f2, columns, res.rows) // original columns (and NOT res.columns) passed in to ensure type remains the same
    
            if (res2.success) {

                
                let e = compareColumns(columns, res.columns)
                if (e === "") {
                    let newFiles = []
                    newFiles.push(res.file)
                    setFiles(newFiles)
                    setError("")
                    setCols(res.columns || [])
                    setRows(res2.rows || [])
                } else {
                    setError(e)
                }
            } else {
                setError(res.validationErrors || "Parsing error")
            }
        }      

        if (res.success) {
            // parse again, this time without dynamic types
            Papa.parse(f, {
                header: true,
                complete: finalizeRows,
                dynamicTyping: false,
                skipEmptyLines: true
            });
        } else {
            setError(res.validationErrors || "Parsing error")
        }
    }

    const getColumns = () => {
        let newColumns = [];

        cols.forEach(c => {
            c.headerRenderer = p => (
                
                <div className="leading-7">
                    <div className="text-left">{c.name}</div>
                    <div className="flex-auto py-1.5 italic font-normal text-xs text-left">({c.type})</div>
                </div>
                
            );
            newColumns.push(c)
        })
        return newColumns
    }
 
    // component
    return (
        <Modal 
            cancel={props.cancel} 
            title={`Upload data to ${table}`}
            enablePrimary={files.length > 0}
            primaryTitle="Upload" 
            primaryOnClick={ () => { upload() } }
        >
            <div className="w-full">

                {
                    files.length > 0
                    ?   <div>
                            <div>
                                <DataGrid columns={getColumns()}
                                    defaultColumnOptions={{
                                        sortable: true,
                                        resizable: true,
                                        minWidth: 120
                                    }}
                                    enableFilterRow={true}
                                    headerRowHeight={60}
                                    rows={rows}
                                    rowKeyGetter={(row) => { 
                                        return row.__WIO_ID 
                                    }}
                                />
                            </div>
                            <div className="flex flex-row">
                                <Dropdown name="actionSelect"
                                    outerDivClass="w-1/4 p-1"
                                    value={action}
                                    onChange={(e) => {
                                        setError("")
                                        setAction(e.target.value)
                                    }}
                                >
                                    <option value={"append"}>Append</option>
                                    {/* <option value={"merge"}>Merge</option> */}
                                    <option value={"replace"}>Replace</option>
                                </Dropdown>
                                {
                                    action === "merge"
                                    ?   <Dropdown name="columnSelect"
                                            outerDivClass="w-3/4 p-1"
                                            value={mergeColumn}
                                            onChange={(e) => { setMergeColumn(e.target.value) }}
                                        >
                                            <option key={-1} value={"-none-"}>Merge Column</option>
                                            {
                                                columns.map((c, i) => {
                                                    return <option key={i} value={c.key}>{c.name}</option>
                                                })
                                            }
                                        </Dropdown>
                                    : null
                                }
                            </div>
                            <div className="text-red-500 pl-4">
                                {error.split('\n').map((s,i) => <p key={i}>{s}</p>)}
                            </div>
                        </div>
                    : <AddTableUpload files={files} error={error} onDrop={onDrop} />
                }
            </div>
        </Modal>
    );
}

export default GridUploadModal;