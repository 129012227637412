import React from "react";
import PropTypes from "prop-types";

class Card extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className="rounded-lg my-8 p-4 pb-8 pt-2 shadow-lg bg-gray-100 dark:bg-gray-800 sm:w-5/6">
                <div className="w-full font-bold sm:text-lg xl:text-xl">{this.props.title}</div>
                <div className="overflow-y-auto h-full max-h-100 divide-y divide-gray-300">
                    {this.props.children}
                </div>                
            </div>
        );
    }
}

export default Card;

Card.propTypes = {
    title: PropTypes.string,
};
