import React from "react";
// import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Images
import WinterfaceFull from "../images/WinterfaceFull"

class Page404 extends React.Component {

    // constructor(props) {
    //     super(props);      
    // }

    render() {
        return (
            <div className="pl-20 py-20 h-screen w-full">
                <NavLink exact to={"/"}>
                    <div className="mx-auto max-w-md">
                        <WinterfaceFull className="fill-current dark:text-white" />
                    </div>

                    <div>Sorry, this isn't a valid path.</div>
                </NavLink>
        </div>
        );
    }
}

export default Page404;

Page404.propTypes = {
    //    selectedTable: PropTypes.string.isRequired,
};
