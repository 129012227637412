import React from "react";
import PropTypes from "prop-types";

class Dropdown extends React.Component {
   
    render() {
   
        return (
            <div className={this.props.outerDivClass}>
                <select name={this.props.name}
                    data-testid={this.props.name}
                    className="w-full p-2 h-10 m-1 rounded-md ring-2 ring-gray-200 text-black focus:outline-none focus:ring-wblue" 
                    value={this.props.value}
                    onChange={(e) => {
                        this.props.onChange(e)
                    }}
                    disabled={this.props.disabled ? true : null}
                >
                    {this.props.children}
                </select>
                {
                    this.props.error
                        ? <span className="pl-2 text-red-500 text-sm">{this.props.error}</span>
                        : null
                }
            </div>
        );
    }

}

export default Dropdown;

Dropdown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    outerDivClass: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
};
