import { Route } from "react-router-dom";

import { withAuthenticationRequired } from "@auth0/auth0-react";

const AuthRoute = ({ children, ...rest }) => {

    return (
        <Route
            {...rest}
            render={ ({ location }) => (children) 
            }
        />
    );
}


export default withAuthenticationRequired(AuthRoute);