
import React from "react";
import PropTypes from "prop-types";

// custom components
import Button from "../../common/button/Button"
import ButtonPrimary from "../../common/button/ButtonPrimary"
import Dropdown from '../../common/dropdown/Dropdown'
import Input from "../../common/form/Input"

// icons
import { Check, X, Plus } from 'react-feather';

class AddTableColumnDefs extends React.Component {

    // constructor(props) {
    //     super(props);


    //     this.state = {
            
    //     }
    // }

    render() {

        return (
            <div>
                {
                    this.props.columns.length > 0
                        ?   
                            <div className="flex flex-row dark:text-black">
                                
                                <Dropdown name="schemaSelect"
                                    outerDivClass="flex flex-col w-1/2 mr-2"
                                    value={this.props.schema}
                                    onChange={this.props.onChangeSchema} 
                                    error={this.props.errors.hasOwnProperty("schema") ? this.props.errors.schema : undefined }
                                    disabled={this.props.schemaDisabled ? true : null}
                                >
                                    <option value={"-none-"}>Choose Schema</option>
                                    {
                                        this.props.schemas.map((s, i) => {
                                            return <option key={i} value={s.schema}>{s.schema}</option>
                                        })
                                    } 
                                </Dropdown>
                                <Input placeHolder="Table Name"
                                        outerClass="w-1/2"
                                        className="m-1"
                                        error={this.props.errors.hasOwnProperty("table") ? this.props.errors.table : undefined }
                                        inputValue={this.props.tableName}
                                        onChange={this.props.onChangeTableName}
                                />
                            </div>
                        : null
                }
                
                <div className="flex flex-col" data-testid="addTableColumnDefs">
                    <div className="flex flex-row mx-2 py-1 w-full-0.5 overflow-x-auto overflow-y-hidden">
                    {
                        this.props.columns.map((col, i) => {
                            return <div key={i} className="flex flex-row my-1 py-1 h-full border">
                                <div className="mx-0.5 my-1" data-testid={"buttonDelete_"+i}>
                                    <button
                                        title="Delete"
                                        className="mx-auto w-8 h-8 text-red-500 focus:outline-none rounded-full hover:bg-red-400 hover:text-white"
                                        onClick={this.props.deleteColumn.bind(this, i)}
                                    >
                                        <X className="h-5 w-5 mx-auto" /> 
                                    </button>
                                </div>
                                <div key={i} 
                                    className={"flex flex-col hover:text-wblue mr-3 " + (this.props.activeColumn === i ? "text-wblue": "")}
                                    onClick={this.props.editColumn.bind(this, i)}
                                >
                                    <div className="font-bold" data-testid={"columnName_"+i}>{col.name}</div>
                                    <div className="text-sm italic" data-testid={"columnType_"+i}>{col.type}</div>
                                </div>
                            </div>
                        })
                    }
                    </div>
                    <div className="flex flex-col w-1/2 m-2 dark:text-black">
                        <div className="flex">
                            <div className="flex-grow">
                                <Input placeHolder="Column Name"
                                        className="m-1"
                                        error={this.props.errors.hasOwnProperty("name") ? this.props.errors.name : undefined }
                                        inputValue={this.props.columnName}
                                        onChange={this.props.onChangeColumnName}
                                />
                            </div>
                            <div className="m-1 dark:text-white">
                                <div className="flex">
                                    
                                    {
                                        this.props.activeColumn === -1 
                                            ?   <button
                                                    title={"Add Column"}
                                                    className="w-8 h-8 focus:outline-none rounded-full text-wblue hover:bg-wblue hover:text-white"
                                                    onClick={() => {this.props.addColumn()}}
                                                >
                                                    <Plus className="h-5 w-5 mx-auto" /> 
                                                </button>
                                            :   <button
                                                    title={"Modify Column"}
                                                    className="w-8 h-8 focus:outline-none rounded-full text-green-500 hover:bg-green-400 hover:text-white"
                                                    onClick={() => {this.props.addColumn()}}
                                                >
                                                    <Check className="h-5 w-5 mx-auto" /> 
                                                </button>
                                                    
                                    }
                                        
                                    {
                                        this.props.activeColumn === -1 
                                            ?   null
                                            :   <button
                                                    title="Cancel"
                                                    className="w-8 h-8 text-red-500 focus:outline-none rounded-full hover:bg-red-400 hover:text-white"
                                                    onClick={this.props.editColumn.bind(this, -1)}
                                                >
                                                    <X className="h-5 w-5 mx-auto" /> 
                                                </button>
                                                    
                                    }
                                </div>
                            </div>
                        </div>
                        <Dropdown name="dataTypeSelect"
                            outerDivClass={this.props.activeColumn === -1 ? "mr-12" : "mr-20"}
                            value={this.props.dataType}
                            onChange={this.props.onChangeDataType}
                            disabled={this.props.defType === "modify" && this.props.activeColumn > -1 ? true : null}
                        >
                            <option value="Text">Text</option>
                            <option value="Date">Date</option>
                            <option value="Integer">Integer</option>
                            <option value="Decimal">Decimal</option>
                        </Dropdown>
                    </div>
                </div>
                
                {
                    this.props.columns.length > 0 && !this.props.inModal
                    ?   <div className="flex justify-end py-2">
                            <div className="mx-1 w-48">
                            <ButtonPrimary title={this.props.defType === "modify" ? "Modify Table" : "Add Table"} onClick={this.props.addTable} />
                            </div>
                            <div className="mx-1 w-48">
                                <Button title="Cancel" onClick={this.props.resetState} />
                            </div>
                        </div>
                    : null
                }
            </div>
        );
    }
}

export default AddTableColumnDefs;

AddTableColumnDefs.propTypes = {
    activeColumn: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    dataType: PropTypes.string.isRequired,
    defType: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    schema: PropTypes.string.isRequired,
    schemaDisabled: PropTypes.bool.isRequired,
    schemas: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    addTable: PropTypes.func.isRequired,
    addColumn: PropTypes.func.isRequired,
    editColumn: PropTypes.func.isRequired,
    deleteColumn: PropTypes.func.isRequired,
    onChangeColumnName: PropTypes.func.isRequired,
    onChangeDataType: PropTypes.func.isRequired,
    onChangeSchema: PropTypes.func.isRequired,
    onChangeTableName: PropTypes.func.isRequired,
    resetState: PropTypes.func,
    inModal: PropTypes.bool,
};



