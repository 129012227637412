import React from "react";
import PropTypes from "prop-types";

class ButtonPrimary extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <button className="focus:outline-none px-4 p-2 bg-wblue rounded mx-auto w-full text-white hover:bg-wblue-light hover:text-black" 
                disabled={this.props.disabled || undefined}
                onClick={this.props.onClick}>
                {this.props.title}
            </button>
        );
    }

}

export default ButtonPrimary;

ButtonPrimary.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
