import React from "react";

// Images
import WinterfaceFull from "../images/WinterfaceFull"
import Spinner from "../components/common/Spinner";

class PageLoading extends React.Component {

    // constructor(props) {
    //     super(props);      
    // }

    render() {
        return (
            <div className="pl-20 py-20 h-screen w-full">
                <div className="mx-auto max-w-md">
                    <WinterfaceFull className="fill-current dark:text-white" />
                    <div className="mx-auto w-16 my-4">
                        <Spinner />
                    </div>
                </div>
        </div>
        );
    }
}

export default PageLoading;
