import React from "react";

// custom components
import Input from '../common/form/Input'
import Modal from '../common/modals/Modal'

// icons
import { Check, X } from 'react-feather';

const GridFilterModal = (props) => {
 
    let {filter} = props

    return (
        <Modal cancel={props.closeFilter} title={`Add filter to: ${filter.key}`}>
            <div className="flex flex-row w-1/2">
                <Input 
                    placeHolder={"Enter filter pattern"}
                    onChange={(e) => {props.changeFilter(e.target.value)}}
                    inputValue={props.filterTerm}
                    outerClass={"flex-1"} 
                    autoFocus
                />
                <button
                    title="Save Filter"
                    className="mt-2 w-8 h-8 text-green-500 focus:outline-none rounded-full hover:bg-green-400 hover:text-white"
                    onClick={() => {props.addFilter()}}
                >
                    <Check className="h-5 w-5 mx-auto" /> 
                </button>
                <button
                    title="Delete Filter"
                    className="mt-2 w-8 h-8 text-red-500 focus:outline-none rounded-full hover:bg-red-400 hover:text-white"
                    onClick={() => {props.deleteFilter(filter.key)}}
                >
                    <X className="h-5 w-5 mx-auto" /> 
                </button>
            </div>
            
            <div>
                {
                    filter.type === "Decimal" || filter.type === "Integer"
                    ?   <div className="pt-4 p-2">
                            <div>Valid patterns:</div>
                            <ul>
                                <li className={"pl-5 text-sm flex flex-row"}>
                                    <div className="w-32">Greater than, e.g.: </div>
                                    <code className={"text-wblue"}>{">"}15</code>
                                </li>
                                <li className={"pl-5 text-sm flex flex-row"}>
                                    <div className="w-32">Less than, e.g.: </div> 
                                    <code className={"text-wblue"}>{"<"}20</code>
                                </li>
                                <li className={"pl-5 text-sm flex flex-row"}>
                                    <div className="w-32">Range, e.g.: </div>
                                    <code className={"text-wblue"}>15-20</code>
                                </li>
                                <li className={"pl-5 text-sm flex flex-row"}>
                                    <div className="w-32">Exact, e.g.: </div>
                                    <code className={"text-wblue"}>15.22</code>
                                </li>
                                <li className={"pl-5 text-sm flex flex-row"}>
                                    <div className="w-32">Empty, returns nulls</div>
                                </li>
                            </ul>
                        </div>
                    :   <div className="pt-4 p-2">
                            <div>Enter any part of the word to search.</div>
                            <div>Leaving the field blank and clicking accept will show only empty values.</div>
                        </div>
                }
            </div>
        </Modal>
    );
}

export default GridFilterModal;