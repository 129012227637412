const getFilterRule = (filterTerm) => {
    // handle dash (range)
    const dashIdx = filterTerm.indexOf('-');
    if (dashIdx > 0) {
        const begin = parseFloat(filterTerm.slice(0, dashIdx));
        const end = parseFloat(filterTerm.slice(dashIdx + 1));
        return { 
            rule: "range", 
            begin: begin, 
            end: end 
        };
    }

    // handle greater than
    if (filterTerm.includes('>')) {
        const begin = parseFloat(filterTerm.slice(filterTerm.indexOf('>') + 1));
        return { 
            rule: "gt", 
            begin: begin 
        };
    }
    
    // handle less than
    if (filterTerm.includes('>')) {
        const begin = parseFloat(filterTerm.slice(filterTerm.indexOf('<') + 1));
        return { 
            rule: "lt", 
            begin: begin 
        };
    }

    
    // handle empty
    if (filterTerm === "") {
        return {
            rule: "null"
        }
    }

    const val = parseFloat(filterTerm)

    // handle NaN
    if (isNaN(val)) {
        return {
            rule: "null"
        }
    }

    // handle exact
    return {
        rule: "exact",
        val
    }
}

const filterNumber = (r, key, filterTerm) => {

    const filt = getFilterRule(filterTerm);

    // handle range
    if (filt.rule === "range") {
        if (r[key] === undefined || r[key] === null || r[key] < filt.begin || r[key] > filt.end) {
            return false
        }
        return true
    }

    // handle greater than
    if (filt.rule === "gt") {
        if (r[key] === undefined || r[key] === null || r[key] < filt.begin) {
            return false
        } 
        return true
    }
    
    // handle less than
    if (filt.rule === "lt") {
        if (r[key] === undefined || r[key] === null || r[key] > filt.begin) {
            return false
        } 
        return true
    }
    
    // handle exact
    if (filt.rule === "exact") {
        if (r[key] === undefined || r[key] === null || parseFloat(r[key]) !== filt.val) {
            return false
        } 
        return true
    }

    // handle null
    if (filt.rule === "null") {
        if (r[key] !== undefined && r[key] !== null && r[key] !== "") {
            return false
        } 
        return true
    }

    return false;

}


const filterRows = (filters, rows) => {

    return rows.filter(
        r => {
            for (let key in filters) {
                switch (filters[key].type) {
                    // if text
                    case "Text":
                    case "Date":
                        if (filters[key].filterTerm === "" && r[key] !== undefined && r[key] !== null) {
                            return false;
                        }
                        if (filters[key].filterTerm !== "" 
                                && (r[key] === undefined 
                                        || r[key] === null 
                                        || !r[key].includes(filters[key].filterTerm)
                                    )
                            ) {
                            return false;
                        }
                        break;
                    // if number
                    case "Integer":
                    case "Decimal":
                        if (!filterNumber(r, key, filters[key].filterTerm)) {
                            return false
                        }
                        break;
                    default:
                        
                }
            }

            return true;
        }
    )
}

export default filterRows