import React from "react";
import PropTypes from "prop-types";

// icons
import { Trash2, Download, Share, Save, Lock, RefreshCw } from 'react-feather';
import UndoIcon from "./icons/undoIcon"
import TableIcon from "./icons/tableIcon"

class GridActions extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="flex block h-10 w-full align-top float-left overflow-hidden">
                <ul className="flex items-end text-base ml-0.5">
                    <li className="hover:text-wblue" >
                        {
                            this.props.isUnlocked
                            ?   <button
                                    title="Save"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "save")}
                                >
                                    <Save className="h-5 w-5 mx-auto" /> 
                                </button>
                            :   <button
                                    title="Edit"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "edit")}
                                >
                                    <Lock className="h-5 w-5 mx-auto" /> 
                                </button>
                        }                   
                        
                    </li>
                    
                    {
                        this.props.isUnlocked
                        ?   null
                        :   <li className="hover:text-wblue" >
                                <button
                                    title="Modify Table"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "modify")}
                                >
                                    <TableIcon className="h-5 w-5 mx-auto" /> 
                                </button>
                            </li>
                    }

                    
                    {
                        this.props.isUnlocked
                        ?   null
                        :   <li className="hover:text-wblue" >
                                <button
                                    title="Refresh data"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "refresh")}
                                >
                                    <RefreshCw className="h-5 w-5 mx-auto" /> 
                                </button>
                            </li>
                    }
                
                    <li className="mx-1 mb-1 border-l border-opacity-50" >
                        <div className="h-6 w-0.5"></div>
                    </li>

                    <li className="hover:text-wblue" >
                        <button
                            title="Download"
                            className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                            onClick={this.props.buttonHandler.bind(this, "download")}
                        >
                            <Download className="h-5 w-5 mx-auto" /> 
                        </button>
                    </li>
                    
                    {/* <li className="hover:text-wblue" >
                        <button
                            title="Share"
                            className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                            onClick={this.props.buttonHandler.bind(this, "share")}
                        >
                            <Share2 className="h-5 w-5 mx-auto" /> 
                        </button>
                    </li> */}
                    
                    {
                        this.props.isUnlocked
                        ?   null
                        :   <li className="hover:text-wblue" >
                            <button
                                title="Upload"
                                className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                onClick={this.props.buttonHandler.bind(this, "upload")}
                            >
                                <Share className="h-5 w-5 mx-auto" /> 
                            </button>
                        </li>
                    }
                    
                    {/* <li className="hover:text-wblue" >
                        <button
                            title="History"
                            className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                            onClick={this.props.buttonHandler.bind(this, "history")}
                        >
                            <Clock className="h-5 w-5 mx-auto" /> 
                        </button>
                    </li> */}
                    
                    {
                        this.props.selectActions
                        ?   
                            <li className="mx-1 mb-1 border-l border-opacity-50" >
                                <div className="h-6 w-0.5"></div>
                            </li>
                        : null
                    }
                    {
                        this.props.selectActions
                        ?   
                            <li className="hover:text-wblue" >
                                <button
                                    title="Undo Change"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "revert")}
                                >
                                    <UndoIcon className="h-4 w-4 mx-auto" />
                                </button>
                            </li>
                        : null
                    }     
                    {
                        this.props.selectActions
                        ?   
                            <li className="hover:text-wblue" >
                                <button
                                    title="Delete"
                                    className="mx-auto w-8 h-8 focus:outline-none rounded-full hover:bg-gray-600"
                                    onClick={this.props.buttonHandler.bind(this, "delete")}
                                >
                                    <Trash2 className="h-5 w-5 mx-auto" /> 
                                </button>
                            </li>
                        : null
                    }        
                </ul>
            </div>
        );
    }
}

export default GridActions;

GridActions.propTypes = {
    selectActions: PropTypes.bool.isRequired,
    isUnlocked: PropTypes.bool.isRequired,
    buttonHandler: PropTypes.func.isRequired,
};
