import React from "react";

// Custom Components
import SettingsListContainer from "../common/settingsList/SettingsListContainer"
import TenantItem from "./TenantItem"

function TenantList(props) {

    return (
        <div className="pl-20 w-full">
            <div className="w-full sm:pl-20">
                <div className="p-4 mx-auto text-left md:max-w-7xl xl:max-w-5xl">
                    <div className="rounded-lg my-8 p-4 pb-8 pt-2 sm:w-5/6">
                        <SettingsListContainer leader="Instances" >
                            {
                                Object.keys(props.tenants).map(function(k, i) {
                                    let role = props.tenants[k];
                                    return <TenantItem 
                                                key={k} 
                                                tenantName={k}
                                                tenantRole={role}
                                                currentTenantFlag={props.currentTenant === k}
                                                setTenant={props.updCurrentTenant}
                                            />
                                })
                            }

                        </SettingsListContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantList;