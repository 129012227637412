import {
    BrowserRouter as Router
} from "react-router-dom";

// Context
import { AuthProvider } from "./contexts/authContext";
import { DataProvider } from "./contexts/dataContext";
import { PageProvider } from "./contexts/pageContext";
import { SocketProvider } from "./contexts/socketContext";
import { ToastContainer } from 'react-toastify';


// Custom Components
import OuterNavBar from "./components/outerNavBar/OuterNavBar";
import Routes from "./Routes"

function App() {

    return (
        <Router>
            <div className="text-center h-screen bg-white dark:bg-wdarkgrey dark:text-wgray">
                
                    <AuthProvider>
                        <SocketProvider>
                            <PageProvider>
                                <DataProvider>
                                    <OuterNavBar />
                                    <Routes/>
                                </DataProvider>
                            </PageProvider>
                        </SocketProvider>
                    </AuthProvider>
            </div>
            <ToastContainer 
                position="bottom-right" 
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Router>
    );
}

export default App;