import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-toastify';


// Socket Client
import * as io from 'socket.io-client';
const ENDPOINT = `${process.env.REACT_APP_SOCKET_PROTOCOL}${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_ENDPOINT : window.WSS_ENDPOINT}`;

export const SocketContext = createContext(null);

export const socket = io.connect(ENDPOINT, {
    withCredentials: true,
    auth: {
        token: null
    }
});

// console.log("creating socket")

export const SocketProvider = props => { 

    const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
    const [token, setToken] = useState(null);
    const [userProfile, setUserProfile] = useState({});
    const [loading, setLoading] = useState(true);

    const currentPath = useLocation().pathname;

    // AUTH: set token & reconnect
    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            const getToken = async () => {
                const myToken = await getAccessTokenSilently()
                setToken(myToken)
            }
            getToken()
        }

        if (token !== null && token.length > 0) {
            socket.auth.token = `Bearer ${token}`;
            socket.auth.user = user;
            socket.connect()
        }

    }, [token, setToken, getAccessTokenSilently, isAuthenticated, isLoading, user])

    // LISTENERS: socket
    useEffect(() => {

        socket.on("connect", () => {
            // console.log(`Connected as ${socket.id}`)
        })

        socket.on('connect_error', (error) => {
            // console.log("connect error", error)
        });

        socket.on("disconnect", (reason) => {
            // console.log("disconnected because:", reason)
        });

        return () => {
            socket.removeAllListeners("connect");
            socket.removeAllListeners("connect_error");
            socket.removeAllListeners("disconnect");
        }

    }, [])

    // LISTENERS: user
    useEffect(() => {
        socket.on("emitUser", (d) => {
            // console.log("setting user", d)
            setUserProfile(d)
            setLoading(false)
        })
        
        socket.on("emitNotification", (msg) => {
            if (msg.type === "error") {
                toast.error(msg.message);
            } else if (msg.type === "success") {
                toast.success(msg.message);
            } else if (msg.type === "info") {
                toast.info(msg.message);
            } else {
                toast(msg.message);
            }
            
        })

        
        return () => {
            socket.off("emitUser")
            socket.off("emitNotification")
        };

    }, [setUserProfile])

    
    // LISTENERS: setCurrentInstance
    useEffect(() => {

        // update current tenant if direct link
        let pathParts = currentPath.split("/")
        if (pathParts.length > 2 && !loading) {
            if (userProfile.currentTenant !== pathParts[1] && userProfile.currentTenant.length > 0) {
                setLoading(true)
                socket.emit("updCurrentTenant", pathParts[1])
            }
        }        

    }, [setLoading, currentPath, userProfile, loading])

    //////////////
    // EMITTERS //
    //////////////

    const checkUniqueInstance = (value, cb) => {
        socket.emit("reqCheckUniqueInstance", { instance: value }, cb)
    }

    const submitOnboarding = (data, cb) => {
        socket.emit("updOnboarding", data, cb)
    }

    const testConnection = (data, cb) => {
        socket.emit("reqTestConnection", data, cb)
    }

    const resendVerification = () => {
        socket.emit("reqResendVerification", {})
    }

    const updCurrentTenant = (tenantName) => {
        setLoading(true)
        socket.emit("updCurrentTenant", tenantName)
    }

    const updPrimaryTenant = (tenantName) => {
        socket.emit("updPrimaryTenant", tenantName)
    }

    const leaveTenant = (tenantName) => {
        socket.emit("deleteTenantForCurrentUser", tenantName)
    }

    //////////////////////////////
    // CLIENT-SIDE NOTIFICATION //
    //////////////////////////////

    const addNotification = (msg, type) => {
        
        if (type === "error") {
            toast.error(msg);
        } else if (type === "success") {
            toast.success(msg);
        } else if (type === "info") {
            toast.info(msg);
        } else {
            toast(msg);
        }
    }

    ///////////////
    // COMPONENT //
    ///////////////

    return (
        <SocketContext.Provider value={
            {
                socket, 
                userProfile, 
                loading,
                setLoading,
                checkUniqueInstance, 
                submitOnboarding, 
                testConnection, 
                addNotification, 
                resendVerification,
                updCurrentTenant,
                updPrimaryTenant,
                leaveTenant
            }
        }>
            {props.children}
        </SocketContext.Provider>
    );
};