
import React from "react";
import PropTypes from "prop-types";

// custom components
import AddTableColumnDefs from "../addTable/AddTableColumnDefs"
import Modal from "../../common/modals/Modal"

class ConfirmList extends React.Component {


    render() {
        let { actions, columns } = this.props

        const toBeDeleted = Object.values(actions.dropColumn);
        const toBeAltered = Object.values(actions.alterColumn);
        let toBeAdded = [];

        columns.forEach((col) => {
            if (actions.addColumn.hasOwnProperty(col.name)) {
                toBeAdded.push(actions.addColumn[col.name])
            }
        });


        return (
            <div>
                <div className="mb-3">
                    After confirming, the following will change on your table:
                </div>
                {/* drops */}
                {
                    toBeDeleted.length > 0
                        ? <div className="mb-3">
                            <div className="font-bold">Remove these columns:</div>
                            <div className="italic text-sm">(this also removes ALL DATA in these columns)</div>
                            <ul className="pl-4">
                                {
                                    toBeDeleted.map((col, i) => {
                                        return <li key={i}>{col.name}<span className="italic"> ({col.type})</span></li>
                                    })
                                }

                            </ul>
                        </div>
                        : null
                }

                {/* alters */}
                {
                    toBeAltered.length > 0
                        ? <div className="mb-3">
                            <div className="font-bold">Rename these columns:</div>
                            <ul className="pl-4">
                                {
                                    toBeAltered.map((col, i) => {
                                        return <li key={i}>
                                            <strong>{col.oldName}</strong> to <strong>{col.name}</strong>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }


                {/* add */}
                {
                    toBeAdded.length > 0
                    ? <div className="mb-3">
                            <div className="font-bold">Add these columns:</div>
                            <ul className="pl-4">
                                {
                                    toBeAdded.map((col, i) => {
                                        return <li key={i}>{col.name}<span className="italic"> ({col.type})</span></li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }

                {/* rename table */}
                {
                    actions.alterTable.hasOwnProperty("from") && actions.alterTable.hasOwnProperty("to")
                        ? <div className="mb-3">
                            <div className="font-bold">Rename the table:</div>
                            <ul className="pl-4">
                                <li><strong>From: {actions.alterTable.from}</strong></li>
                                <li><strong>To: {actions.alterTable.to}</strong></li>
                            </ul>
                        </div>
                        : null
                }

            </div>
        );
    }
}

class ConfirmDeleteText extends React.Component {


    render() {


        return (
            <div>
                <div className="mb-3">
                    Are you sure you want to delete the table: <strong>{this.props.tableName}</strong>?
                </div>
                <div className="mb-3">
                    All history will also be removed for this table.
                </div>
            </div>
        );
    }
}


class ModifyTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeColumn: -1,
            columns: JSON.parse(JSON.stringify(this.props.columns)),
            columnName: "",
            dataType: "Text",
            schema: this.props.sourceSchema,
            tableName: this.props.tableName,
            validationErrors: {},
            actions: {
                dropColumn: {},
                alterColumn: {},
                addColumn: {},
                alterTable: {},
            },
            openConfirm: false,
            openConfirmDelete: false,
        }
    }

    addTable = () => {
        let { schema, validationErrors } = this.state

        validationErrors = this.validateTable(this.state.tableName)

        if (validationErrors.hasOwnProperty("table") || validationErrors.hasOwnProperty("schema")) {
            this.setState({
                validationErrors
            })
            return
        }

        if (schema === "-none-") {
            validationErrors["schema"] = "Please select a schema"
            this.setState({
                validationErrors
            })
            return
        }

        this.setState({
            openConfirm: true,
        })

    }

    deleteTable = () => {

        this.setState({
            openConfirmDelete: true,
        })

    }


    validateColumn = (columns, columnName, dataType) => {
        let errors = {}
        let { validationErrors } = this.state


        const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
        if (!regex.test(columnName)) {

            errors["name"] = "Name can only contain letters, numbers, and underscores"
        }

        if (columnName.length < 1) {
            errors["name"] = "Column name must be filled in"
        }

        let pos = columns.findIndex(c => c.name.toLowerCase() === columnName.toLowerCase());
        if (pos > -1) {
            errors["name"] = "Column names must be unique"
        }

        let validTypes = ["Text", "Integer", "Decimal", "Date"]
        let p = validTypes.findIndex(t => t === dataType);

        if (p === -1) {
            errors["type"] = "Data type is invalid"
        }

        if (validationErrors.hasOwnProperty("table")) {
            errors.table = validationErrors.table
        }

        return errors
    }

    addColumn = () => {
        let { actions, activeColumn, columns, columnName, dataType } = this.state;

        let errors = this.validateColumn(columns, columnName, dataType)

        if (errors.hasOwnProperty("name") || errors.hasOwnProperty("type")) {
            this.setState({
                validationErrors: errors,
            });
            return;
        }

        let newCol = {
            name: columnName,
            type: dataType,
        }


        if (activeColumn > -1) {                        // if editing
            let currentCol = columns[activeColumn];

            if (actions.addColumn.hasOwnProperty(currentCol.name)) {    // if recent add
                delete actions.addColumn[currentCol.name];
                actions.addColumn[newCol.name] = newCol;
            } else {                                                    // if existing
                newCol.oldName = currentCol.oldName
                    ? currentCol.oldName
                    : currentCol.name
                newCol.oldKey = currentCol.oldKey
                    ? currentCol.oldKey
                    : currentCol.key 
                        ? currentCol.key 
                        : currentCol.name
                actions.alterColumn[newCol.oldName] = newCol;
            }

            columns.splice(activeColumn, 1, newCol);

        } else {                                        // if new
            actions.addColumn[newCol.name] = newCol;
            columns.push(newCol);
        }

        this.setState({
            actions,
            activeColumn: -1,
            columns,
            columnName: "",
            dataType: "Text",
            validationErrors: errors,
        });
    }

    editColumn = (i) => {
        let { activeColumn, columns, columnName, dataType, validationErrors } = this.state;

        if (activeColumn === i) {
            return
        }

        delete validationErrors.name;
        delete validationErrors.type;

        if (i === -1) {
            this.setState({
                activeColumn: -1,
                columnName: "",
                dataType: "Text",
                validationErrors,
            });
        } else {
            let columnToEdit = columns[i];

            columnName = columnToEdit.name;
            dataType = columnToEdit.type;
            activeColumn = i;

            this.setState({
                activeColumn,
                columnName,
                dataType,
                validationErrors,
            });
        }
    }

    deleteColumn = (i) => {
        let { columns, actions } = this.state;

        let colTBD = columns[i]

        if (actions.addColumn.hasOwnProperty(colTBD.name)) {
            delete actions.addColumn[colTBD.name]
        } else {
            actions.dropColumn[colTBD.name] = colTBD
        }

        columns.splice(i, 1);

        this.setState({
            columns,
            actions
        });
    }

    onChangeColumnName = (e) => {
        this.setState({
            columnName: e.target.value
        });
    }

    onChangeDataType = (e) => {
        this.setState({
            dataType: e.target.value
        });
    }

    validateTable = (tableName) => {
        let { validationErrors } = this.state
        let hasError = false;

        const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
        if (!regex.test(tableName)) {
            validationErrors["table"] = "Name can only contain letters, numbers, and underscores"
            hasError = true
        }

        if (tableName.length < 1) {
            validationErrors["table"] = "Table name must be filled in"
            hasError = true
        }

        if (!hasError && validationErrors.hasOwnProperty("table")) {
            delete validationErrors.table
        }

        return validationErrors
    }

    onChangeTableName = (e) => {
        let { actions } = this.state
        let errors = this.validateTable(e.target.value)

        actions.alterTable = { from: this.props.tableName, to: e.target.value }

        this.setState({
            actions,
            tableName: e.target.value,
            validationErrors: errors
        });
    }

    confirmChange = (toBeDeleted, toBeAltered, toBeAdded, tableRename) => {
        this.props.modifyTable(toBeDeleted, toBeAltered, toBeAdded, tableRename)
    }

    confirmDelete = () => {
        this.props.deleteTable()
    }

    render() {

        let {actions, columns} = this.state

        let toBeDeleted = Object.values(actions.dropColumn);
        let toBeAltered = Object.values(actions.alterColumn);
        let toBeAdded = [];

        columns.forEach((col) => {
            if (actions.addColumn.hasOwnProperty(col.name)) {
                toBeAdded.push(actions.addColumn[col.name])
            }
        });

        return (
            <Modal cancel={this.props.toggleModal} 
                title={"Modify table"}
                enablePrimary={true}
                primaryTitle={this.state.openConfirm || this.state.openConfirmDelete ? "Confirm" : "Modify" }
                primaryOnClick={this.state.openConfirm 
                                    ? () => this.confirmChange(toBeDeleted, toBeAltered, toBeAdded, actions.alterTable)
                                    : this.state.openConfirmDelete
                                        ? () => this.confirmDelete()
                                        : this.addTable }
                enableDanger={!this.state.openConfirmDelete && this.props.showDelete}
                dangerTitle={ "Delete Table" }
                dangerOnClick={ this.deleteTable }
            >
                <div data-testid="modifyTableForm">
                    {
                        this.state.openConfirm
                            ? <ConfirmList
                                columns={this.state.columns}
                                actions={this.state.actions}
                            />
                            : this.state.openConfirmDelete
                                ?   <ConfirmDeleteText tableName={this.props.sourceSchema + "." + this.props.tableName} />
                                :   <AddTableColumnDefs
                                        activeColumn={this.state.activeColumn}
                                        columns={this.state.columns}
                                        errors={this.state.validationErrors}
                                        schema={this.state.schema}
                                        schemaDisabled={true}
                                        schemas={this.props.schemas}
                                        tableName={this.state.tableName}
                                        dataType={this.state.dataType}
                                        columnName={this.state.columnName}
                                        defType={"modify"}
                                        addTable={this.addTable}
                                        addColumn={this.addColumn}
                                        editColumn={this.editColumn}
                                        deleteColumn={this.deleteColumn}
                                        onChangeColumnName={this.onChangeColumnName}
                                        onChangeDataType={this.onChangeDataType}
                                        onChangeSchema={() => { }}
                                        onChangeTableName={this.onChangeTableName}
                                        inModal={true}
                                    />
                    }
                </div>
            </Modal>
        );
    }
}


export default ModifyTable;

ModifyTable.propTypes = {
    columns: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    schemas: PropTypes.array.isRequired,
    sourceSchema: PropTypes.string,
    showDelete: PropTypes.bool.isRequired,
    modifyTable: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    deleteTable: PropTypes.func.isRequired,
};
