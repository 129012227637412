import React from "react";
import PropTypes from "prop-types";

// custom components
import Modal from "../common/modals/Modal"
import RoleBoxes from "./RoleBoxes"

class RoleModal extends React.Component {
    
    constructor(props) {
        super(props);
        
        let selectedRoles = {};

        this.props.roles.forEach(r => {
            selectedRoles[r] = true;
        });

        this.state = {
            selectedRoles: selectedRoles,
            error: ""
        }
    }

    selectRole = (role) => {
        let {selectedRoles} = this.state;
        if (role in selectedRoles) {
            let r = selectedRoles[role]
            selectedRoles[role] = !r;
        } else {
            selectedRoles[role] = true;
        }
        this.setState({
            selectedRoles,
            error: ""
        })
    }

    save = () => {
        // set roles from selected
        let roleArray = []
        this.props.allRoles.forEach((r) => {
            if (this.state.selectedRoles.hasOwnProperty(r) && this.state.selectedRoles[r]) {
                roleArray.push(r)
            }
        })

        if (roleArray.length === 0) {
            this.setState({
                error: "Must have at least one role selected"
            })
        }
        else {
            this.props.save(roleArray)
        }
        
    }

    render() {
        let {allRoles} = this.props;
        let {selectedRoles} = this.state;

        return (
            <Modal title="Manage roles" 
                    cancel={this.props.onCancel}
                    enablePrimary={true}
                    primaryTitle="Save" 
                    primaryOnClick={ () => { this.save() } }
            >
                <RoleBoxes allRoles={allRoles} selectedRoles={selectedRoles} selectRole={this.selectRole} />
                <div className="text-red-500 p-2">{this.state.error}</div>
            </Modal>
        );
    }
}

export default RoleModal;

RoleModal.propTypes = {
    allRoles: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
};
