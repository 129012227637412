import React, { useState, useContext, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// contexts
import { SocketContext } from "../contexts/socketContext";

// Custom Components
import Button from "../components/common/button/Button"
import ButtonDanger from "../components/common/button/ButtonDanger"
// import ButtonWarning from "../components/common/button/ButtonWarning"
import Card from "../components/common/Card"
import Container from "../components/common/Container"
import DBConfiguration from "../components/settings/DBConfiguration"
import PlanConfiguration from "../components/settings/PlanConfiguration"
import Modal from "../components/common/modals/Modal"
import SettingsContainer from "../components/common/settings/SettingsContainer"
import Status from "../components/common/status/Status"

// const settings = {
//     dbConfig: {
//         sfinstance: "autodaas.us-east-1",
//         sfrole: "WINTERFACE_ROLE",
//         sfuser: "WINTERFACE_USER",
//         sfwarehouse: "WINTERFACE_WH",
//         // status: "Active"
//         sfdb: "WINTERFACE_DB"
//     },
//     userDefaults: {
//         role: "WINTERFACE_ROLE",
//         db: "WINTERFACE_DB"
//     },
//     account: {
//         name: "Autodaas",
//         plan: "Free",
//         accountOwner: "123",
//         billingAdmin: {
//             name: "Brett Castillo",
//             email: "bcastillo@autodaas.com",
//         },
//     }
// }

const SettingsPage = () => {

    const { socket, testConnection } = useContext(SocketContext);
    const [state, setState] = useState({dbConfigOpen: false, planConfigOpen: false});
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [dbStatus, setDBStatus] = useState("Active");
    const [dbConfig, setDBConfig] = useState({});
    const [planConfig, setPlanConfig] = useState({});
    const { logout } = useAuth0();
    
    //////////////////////////////
    // initial request for data //
    //////////////////////////////

    // fulfillConfig is a callback tied to "reqTenant" to process response
    const fulfillConfig = useCallback((d) => {
        setDBConfig(d);
    }, [setDBConfig]);

    // fulfillPlan is a callback tied to "reqPlan" to process response
    const fulfillPlan = useCallback((d) => {
        setPlanConfig(d);
    }, [setPlanConfig]);

    // initial request for data when coming on to page
    useEffect(() => {

        // only get data if a table is set and hasn't been run before
        if (Object.keys(dbConfig).length === 0) {
            socket.emit("reqTenant", { },
                (response) => { fulfillConfig(response) }
            )
        }
        
        if (Object.keys(planConfig).length === 0) {
            socket.emit("reqPlan", { },
                (response) => { fulfillPlan(response) }
            )
        }

    }, [socket, dbConfig, planConfig, fulfillConfig, fulfillPlan]);
    
    
    // getPortalLink requests "reqPortalLink" to process response
    const getPortalLink = (cb) => {
        socket.emit("reqPortalLink", { },
            (response) => { cb(response) }
        )
    };

    
    ///////////////
    // component //
    ///////////////

    const toggleDBConfigModal = () => {
        setState({...state, dbConfigOpen: !state.dbConfigOpen});
    }

    const togglePlanConfigModal = () => {
        setState({...state, planConfigOpen: !state.planConfigOpen});
    }

    const fulfillTest = (d) => {
        if (d.status === "Success") {
            setDBStatus("Active")
        } else {
            setDBStatus("Error")
        }
    }

    const testConn = () => {
        setDBStatus("Testing...")
        testConnection({testExisting: true}, fulfillTest)
    }

    const submitConn = (def) => {
        socket.emit("updConnection", def,
            (response) => { fulfillConfig(response) }
        )
        toggleDBConfigModal()
    }

    const submitPlan = (def, origTier, cb) => {

        def.origTier = origTier
        
        socket.emit("updPlan", def,
            (response) => {                
                cb(response)
            }
        )
    }

    const deleteInstance = (def) => {
        socket.emit("deleteInstance", def,
            (response) => { setTimeout(() => {  logout({ returnTo: window.location.origin }) }, 2000); }
        )
        setConfirmDelete(false)
    }

    return (
        <div className="pl-20 h-screen w-full">
            <Container leader="Global Settings">
                <Card title="Snowflake Configuration">
                    <SettingsContainer setting="Status" >
                        <Status status={dbStatus} test={testConn}/>
                    </SettingsContainer>
                    <SettingsContainer
                        setting="Modify configuration"
                    >
                        <div>
                            <Button title="Edit" onClick={() => {toggleDBConfigModal()}} />
                            {
                                state.dbConfigOpen
                                ? <DBConfiguration 
                                    config={dbConfig}
                                    cancel={() => {toggleDBConfigModal()}}
                                    submit={submitConn}
                                    test={testConnection}
                                  />
                                : null
                            }  
                            <div className="flex flex-row mt-2">
                                <div className="flex flex-column my-1 sm:flex-row">
                                    <code className="font-medium px-2">Instance:&nbsp;</code>
                                    <code>{dbConfig.sfinstance}</code>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-column my-1 sm:flex-row">
                                    <code className="font-medium px-2">Role:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code>
                                    <code>{dbConfig.sfrole}</code>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-column my-1 sm:flex-row">
                                    <code className="font-medium px-2">User:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code>
                                    <code>{dbConfig.sfuser}</code>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-column my-1 sm:flex-row">
                                    <code className="font-medium px-2">Warehouse:</code>
                                    <code>{dbConfig.sfwarehouse}</code>
                                </div>
                            </div>                    
                            <div className="flex flex-row">
                                <div className="flex flex-column my-1 sm:flex-row">
                                    <code className="font-medium px-2">Database:&nbsp;</code>
                                    <code>{dbConfig.sfdb}</code>
                                </div>
                            </div>             
                        </div>
                    </SettingsContainer>
                </Card>
                {/* <Card title="Default Permissions Settings">
                    <SettingsContainer
                        setting="New User Default Role"
                        settingDesc="This role will be automatically assigned to new users."
                    >
                        <Button title={settings.userDefaults.role} />
                    </SettingsContainer>
                    <SettingsContainer
                        setting="New User Default Database"
                        settingDesc="This database will be automatically assigned to new users."
                    >
                        <Button title={settings.userDefaults.db} />
                    </SettingsContainer>
                </Card> */}
                {/* <Card title="Billing Settings">
                    <SettingsContainer
                        setting="Current Plan"
                        settingDesc="View or upgrade your current plan"
                    >
                        <Button title={settings.account.plan} />
                    </SettingsContainer>
                    <SettingsContainer
                        setting="Payment Information"
                        settingDesc="View or edit your payment information"
                    >
                        <Button title="Edit Payments" />
                    </SettingsContainer>
                    <SettingsContainer
                        setting="Billing Admin"
                        settingDesc="View or edit billing administrator details"
                    >
                        <Button title="Change Billing Admin" />
                    </SettingsContainer>
                </Card> */}
                <Card title="Account Settings">
                    {/* <SettingsContainer
                        setting="Winterface Instance"
                        settingDesc="Change your instance name here.  Warning, this will change the link from where your users access the page from."
                    >
                        <ButtonWarning title={settings.account.name} />
                    </SettingsContainer> */}
                    
                    {
                        planConfig.tier === "Enterprise"
                        ? null
                        : <SettingsContainer
                            setting="Plan & User Seats"
                            settingDesc="Manage plan and number of user seats."
                        >
                            <Button 
                                title={
                                    planConfig.tier === "Free" 
                                        ? `${planConfig.tier} - 1 seat` 
                                        : `${planConfig.tier} - ${planConfig.seats} seats`
                                }  
                                onClick={() => {togglePlanConfigModal()}} 
                            />
                            {
                                state.planConfigOpen
                                ? <PlanConfiguration 
                                    config={planConfig}
                                    cancel={() => {togglePlanConfigModal()}}
                                    submit={submitPlan}
                                    />
                                : null
                            }  
                        </SettingsContainer>
                    }


                    {
                        planConfig.hasCust
                            ? <SettingsContainer
                                setting="Invoice Portal"
                                settingDesc="View past invoices and receipts."
                            >
                                <Button 
                                    title="Invoices"
                                    onClick={() => {
                                        getPortalLink((url) => {
                                            window.location.replace(url);
                                        })
                                    }}
                                />
                            </SettingsContainer>
                            : null
                    }



                    <SettingsContainer
                        setting="Delete Winterface Instance"
                        settingDesc={"This operation is permanent and will erase your instance."}
                    >
                        <ButtonDanger title="Delete Instance" onClick={()=>{setConfirmDelete(true)}}/>
                        {
                            confirmDelete
                            ? <Modal 
                                title="Delete Instance"
                                cancel={() => {setConfirmDelete(false)}}
                                >
                                    <div>
                                        <div>This will immediately delete:</div>
                                        <ul className="pl-4">
                                            <li>- All of your Snowflake connection information</li>
                                            <li>- Any unsaved changes to datasets</li>
                                            <li>- Your and your users' access to the application</li>
                                            <li>- Your access to invoices</li>
                                        </ul>
                                        <div className="pt-4">It will <strong>not</strong> delete any data in Snowflake itself, including:</div>
                                        <ul className="pl-4">
                                            <li>- Any data stored in Snowflake</li>
                                            <li>- The Snowflake user associated with Winterface</li>
                                            <li>- Any roles or warehouses associated with Winterface</li>
                                        </ul>
                                        <div className="pt-4">Your subscription will be cancelled and any unused payment will be credited to your Winterface account. Please contact support to request a refund.</div>
                                        <div className="pt-4">All user logins will remain active and you will still have access to other Winterface accounts your account is associated with.</div>
                                        <div className="py-4">To delete your user account as well, click on your avatar on the bottom left corner of this screen and choose to delete your user account.</div>
                                        <div className="mx-auto w-64"><ButtonDanger title="Delete Instance" onClick={()=>{deleteInstance()}}/></div>
                                        
                                    </div>
                                </Modal>
                            : null
                        }  
                    </SettingsContainer>
                </Card>

            </Container>
            <div className="absolute bottom-5 right-5 text-xs">
                v0.3.5
            </div>
        </div>
        
    );
}

export default SettingsPage;
