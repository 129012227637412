import React, { createContext, useState, useEffect, useContext } from "react";
import { SocketContext } from './socketContext';

export const DataContext = createContext(null);

const initialData = {
    // Database Settings
    databases: [], // array, [{schema: "databaseName", tables: ["tableName1", ...]}...]
    isSet: false,
};

/////////////////////

export const DataProvider = props => {
    const [dbData, setData] = useState(initialData);
    const {socket} = useContext(SocketContext);

    useEffect(() => {
        socket.on("emitTables", data => {
            setData({
                databases: data,
                isSet: true
            });
        });

        return () => {
            socket.off("emitTables")
        };
    }, [socket]);

    const refreshDatabases = () => {
        // console.log("DataProvider - refreshDatabases")
        setData({...dbData, isSet: false})
        socket.emit("reqTables", { refresh: true })
    };
    
    const refreshDatabasesCache = () => {
        // console.log("DataProvider - refreshDatabases")
        setData({...dbData, isSet: false})
        socket.emit("reqTablesCache", { refresh: true })
    };

    // unsetSchema is used to trigger that a refresh is pending
    const unsetSchema = () => {
        setData({...dbData, isSet: false})
    }


    return (
        <DataContext.Provider value={{ dbData, refreshDatabases, unsetSchema, refreshDatabasesCache }}>
            {props.children}
        </DataContext.Provider>
    );
};