import React from "react";
import PropTypes from "prop-types";

class ButtonWarning extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //     }
    // }

    render() {
        
        return (
            <button className="bg-yellow-300 text-black rounded p-2 mx-auto w-full"
                onClick={this.props.onClick}
            >
                <div className={this.props.className || ""}>
                    {this.props.title}
                </div>
            </button>
        );
    }

}

export default ButtonWarning;

ButtonWarning.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
