import React from "react";
import PropTypes from "prop-types";

// icons
import { Check, Plus, X } from 'react-feather';


class SchemaAdd extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isAdding: false,
            schemaName: "",
            error: ""
        }
    }

    toggleIsAdding = () => {
        // flip isAdding, reset state
        this.setState({
            isAdding: !this.state.isAdding,
            schemaName: "",
            error: ""
        })
    }

    onChange = (e) => {
        this.setState({
            schemaName: e.target.value,
            error: this.validateSchema(e.target.value)
        })
    }

    validateSchema = (schema) => {
        let {error} = this.state
        error = ""
        
        const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/
        if (!regex.test( schema )) {
            error = "Must only contain letters, numbers, and underscores"
        }
        
        if (schema.length < 1) {
            error = "Schema must be filled in"
        }

        return error
    }

    onConfirm = () => {
        let schema = this.state.schemaName;
        let error = this.validateSchema(schema)

        if (error.length > 0) {
            this.setState({
                error
            })
        } else {
            this.setState({
                isAdding: !this.state.isAdding,
                schemaName: "",
                error: ""
            })
            this.props.addSchema(schema)
        }
        
    }

    render() {
        let {isAdding} = this.state;

        return (
            <li>
                {
                    isAdding
                    ?   <div className="flex flex-col">
                            <div className="flex flex-row">
                                <input  type="text" 
                                        placeholder={this.props.placeHolder}
                                        className={"mx-2 px-2 text-black focus:outline-none"} 
                                        value={this.state.schemaName}
                                        autoFocus
                                        onChange={this.onChange} 
                                />
                                <button
                                    title="Accept"
                                    className="w-8 h-8 text-green-500 focus:outline-none rounded-full hover:bg-green-400 hover:text-white"
                                    onClick={this.onConfirm}
                                >
                                    <Check className="h-5 w-5 mx-auto" /> 
                                </button>
                                <button
                                    title="Cancel"
                                    className="w-8 h-8 text-red-500 focus:outline-none rounded-full hover:bg-red-400 hover:text-white"
                                    onClick={this.toggleIsAdding}
                                >
                                    <X className="h-5 w-5 mx-auto" /> 
                                </button>
                            </div>
                            <div className="text-sm text-red-500">{this.state.error.length > 0 ? this.state.error : ""}</div>
                        </div>
                    :   <button
                            className="flex px-3 py-1 focus:outline-none hover:text-wblue-dark dark:hover:text-wblue-light"
                            onClick={this.toggleIsAdding}
                        >    
                            <Plus className="inline-block w-4 text-wblue-dark dark:text-wblue-light" />
                            <span className="inline-block px-2" >Add schema</span>                            
                        </button>
                }
                

                
            </li>
        );
    }
}

export default SchemaAdd;

SchemaAdd.propTypes = {
    addSchema: PropTypes.func.isRequired,
};
