import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Custom Components
import DatabaseList from "./database/dblist/DatabaseList"

// Icons
import { ChevronsLeft, RefreshCw } from 'react-feather';


class InnerNavBar extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         expandedDatabases: this.getInitialDB()
    //     }
    // }

    componentDidMount = () => {
        if (!this.props.isTableSet) {
            this.props.setTable(this.props.selectedTable);
        }
    }

    render() {

        return (
            <div className={this.props.innerOpen ? "visible" : "invisible"} data-testid="parentWindow">
                <div className="fixed left-20 bg-gray-200 dark:bg-gray-700 h-full w-80 md:w-96">
                    <div className="flex p-2 text-2xl leading-loose bg-gray-800 text-white h-16">
                        <NavLink to={`/${this.props.tenant}/databases`} onClick={(e) => this.props.clearTable(e)}>
                            Databases
                        </NavLink>
                        <button  
                            className="ml-24 md:ml-40 hover:text-wblue-light"
                            onClick={this.props.toggleMenu}
                            data-testid="collapseButton"
                            >
                            <ChevronsLeft className="inline-block w-8 h-8" />
                        </button>
                        <button  
                            className="ml-4 hover:text-wblue-light"
                            onClick={this.props.refreshDatabases}
                            data-testid="refreshButton"
                            >
                            <RefreshCw className="inline-block w-8 h-8" />
                        </button>
                    </div>
                    <div className="overflow-y-scroll h-5/6">
                        <DatabaseList 
                            selectedTable={this.props.selectedTable}
                            dbData={this.props.dbData}
                            setTable={this.props.setTable}
                            addTable={this.props.addTable}
                            inNavBar={true}
                            addSchema={this.props.addSchema}
                            tenant={this.props.tenant}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default InnerNavBar;

InnerNavBar.propTypes = {
    tenant: PropTypes.string.isRequired,
    innerOpen: PropTypes.bool.isRequired,
    selectedTable: PropTypes.object.isRequired,
    isTableSet: PropTypes.bool.isRequired,
    dbData: PropTypes.array.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    addTable: PropTypes.func.isRequired,
    addSchema: PropTypes.func.isRequired,
    setTable: PropTypes.func.isRequired,
    clearTable: PropTypes.func.isRequired,
    refreshDatabases: PropTypes.func.isRequired,
};
