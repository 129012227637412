import React from "react";
import PropTypes from "prop-types";
import { Plus } from 'react-feather';


class TableItemAdd extends React.Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <li>
                <button
                    className="flex px-8 py-1 focus:outline-none hover:text-wblue-dark dark:hover:text-wblue-light"
                    onClick={(e) => this.props.addTable(this.props.schema, e)}
                >    
                    <Plus className="inline-block w-4 text-wblue-dark dark:text-wblue-light" />
                    <span className="inline-block px-2" >Add table</span>     
                </button>
            </li>
        );
    }
}

export default TableItemAdd;

TableItemAdd.propTypes = {
    schema: PropTypes.string.isRequired,
    addTable: PropTypes.func.isRequired,
};
